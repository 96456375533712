<template>
  <v-app>
    <navtop />
    <navleft />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import navtop from "@/nav/nav.top.vue";
import navleft from "@/nav/nav.left.vue";

export default {
  components: { 
    navtop,
    navleft
   },
  created() {
    this.$store.dispatch('browserRefresh')
  }
}
</script>

<style>
  html { overflow-y: auto }
</style>
