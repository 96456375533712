import Vue from 'vue'
import VueRouter from 'vue-router'

import Welcome from "@/welcome.vue"
import Request from "@/request/Request.vue"
import Login from "@/login/Login.vue"
import Joblist from "@/joblist/Joblist.vue"
import Docreport from "@/joblist/doc_report/Docreport.vue"
import Billing from "@/billing/Billing.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Welcome,
    icon: 'mdi-view-dashboard',
    navlink: true
  },{
    path: '/request',
    name: 'Request',
    component: Request,
    icon: 'mdi-badge-account-horizontal',
    navlink: true
  },{
    path: '/referral',
    name: 'Embedded',
    component: Request,
  },{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited. 
    component: () => import(/* webpackChunkName: "about" */ '@/about.vue'),
    icon: 'mdi-heart-pulse',
    navlink: true
  },{
    path: "/login",
    name: "login",
    component: Login
  },{
    path: "/joblist",
    name: "Job List",
    component: Joblist,
    icon: 'mdi-format-list-bulleted-type',
    requiresAuth: true,
    meta: {requiresAuth: true},
    navlink: true
  },{
    path: "/joblist/report/:study_id",
    name: "report",
    component: Docreport,
    props: true,
    requiresAuth: true,
    meta: {requiresAuth: true}
  },{
    path: "/billing",
    name: "Billing",
    component: Billing,
    icon: 'mdi-bank',
    requiresAuth: true,
    meta: {requiresAuth: true},
    navlink: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
