<template>

<v-row justify="center">
  <v-dialog
    v-model="vmodel_local"
    scrollable
    :persistent = "!(approved || denied)"
    width="800"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{approval_title}}
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary" 
            @click="locked = !locked" 
            :loading="loading"
            v-bind="attrs"
            v-on="on"
          >
              <v-icon >{{locked ? 'mdi-lock' : 'mdi-lock-open'}}</v-icon>
            </v-btn>
          </template>
          <span>{{locked ? 'modify' : 'prevent modification of'}} patient information</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn text color="red darken-1" @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

        <v-card-text height="100%">
          <v-form 
            ref="approval_form"
            lazy-validation
            @submit.prevent="approve">
          <!--v-subheader>Patient Details</v-subheader-->

          <p>Submitted at {{start_date}} from IP address {{ip_address}}</p>

          <v-text-field
            v-model="patient_name"
            :rules="[v => !!v || 'Name is missing']"
            validate-on-blur
            label="Patient Name"
            prepend-icon="mdi-account"
            required
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <div class="d-flex">
            <v-menu
              ref="dob_activator"
              v-model="dob_activator"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="patient_dob"
                  :rules="dobRules"
                  label="Patient Date of Birth"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  clear-icon="mdi-close-circle"
                  placeholder="YYYY-MM-DD"
                  hint='enter patient date of birth in form "YYYY-MM-DD"'
                  outlined
                  filled
                  :readonly = "locked"
                  @change="change"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="study_id === 0"
                ref="dob_picker"
                v-model="patient_dob"
                :max="new Date().toISOString().substr(0, 10)"
                :min="new Date().getFullYear()-125 + '-01-01'"
                @change="save_dob"
              ></v-date-picker>
            </v-menu>

            <v-combobox
              v-model="patient_sex"
              :items="['male', 'female']"
              auto-select-first
              label="Sex"
              hint="choose the sex of the patient"
              :rules="[v => !!v || 'Sex of patient is missing']"
              outlined
              filled
              :readonly = "locked"
              @blur="autocomplete_sex"
              @change="change"
            ></v-combobox>
          </div>

          <v-text-field
            v-model="patient_ohip"
            :rules="ohipRules"
            validate-on-blur
            label="Patient OHIP Number and Version Code"
            prepend-icon="mdi-card-account-details"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="patient_phone"
            :rules="[v => !!v || 'Phone Number is missing']"
            label="Patient Phone Number"
            prepend-icon="mdi-phone"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="patient_email"
            :rules="emailRules"
            validate-on-blur
            label="Patient E-mail"
            prepend-icon="mdi-email"
            required
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-divider></v-divider>

          <v-textarea
            v-model="reason"
            label="Reason / Indication"
            hint="please enter the reason(s) for requesting a heart monitor"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-textarea>

          <v-textarea
            v-model="patient_medications"
            label="Cardiac Medications"
            hint="please list all relevant cardiac medications..."
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-textarea>

          <v-combobox
            v-model="duration"
            :items="durations"
            label="Test Duration"
            prepend-icon="mdi-heart-pulse"
            hint="choose how long the hear monitor will be worn for..."
            :menu-props="{ nudgeBottom: 25 }"
            :rules="[v => !!v || 'Test duration is missing']"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-combobox>

          <v-divider class="my-2"></v-divider>
          <v-subheader>Physician Information</v-subheader>

          <v-text-field
            v-model="primary_dr"
            label="Primary Physician"
            :rules="[v => !!v || 'Primary care provider is missing']"
            prepend-icon="mdi-hospital"
            hint="please enter the name of the patient's primary care provider..."
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="referring_dr"
            label="Referring Physician"
            prepend-icon="mdi-stethoscope"
            hint="if not the primary physician, please enter the name of the care provider referring this patient..."
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="dr_billing"
            :rules="[v => !!v || 'Physician OHIP billing number is missing']"
            label="Doctor Billing Number"
            hint="enter OHIP billing number of referring physician"
            prepend-icon="mdi-credit-card"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="dr_phone"
            label="Physician Phone Number"
            :rules="[v => !!v || 'Phone number is missing']"
            prepend-icon="mdi-phone-classic"
            hint="enter phone number of referring physician"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="dr_fax"
            label="Physician Fax Number"
            :rules="[v => !!v || 'Fax number is missing']"
            prepend-icon="mdi-fax"
            hint="enter fax number of referring physician"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-text-field
            v-model="dr_email"
            :rules="emailRules"
            validate-on-blur
            label="Physician Email"
            prepend-icon="mdi-email"
            hint="enter email address of referring physician"
            outlined
            filled
            :readonly = "locked"
            @change="change"
          ></v-text-field>

          <v-divider></v-divider>
          <v-subheader>Holter Delivery</v-subheader>

          <Shipping
            v-model="shipping"
            :recipients="recipients"
            :readonly = "locked"
            @change="change"
          />

          <v-divider></v-divider>
          <v-subheader>Final Report Destinations</v-subheader>
        
          <Destination
            v-for="(dest, index) in destinations" :key="dest.id"
            :id = "dest.id.toString()"
            :readonly = "locked"
            ignore_untouched
            :checked = "dest.checked"
            :delivery_method = "'mdi-' + dest.service"
            :address = "dest.address"
            :title = "dest.label"
            @change="destination_change(index, $event)"
          />

        <v-divider></v-divider>
        <v-subheader>Additional Information:</v-subheader>

        <v-textarea
          v-model="notes"
          label="Message (optional)"
          hint="provide any additional messages, comments, or notes for the office"
          outlined
          filled
          :readonly = "locked"
          @change="change"
        ></v-textarea>

        <v-divider></v-divider>
        <v-subheader>For Office Use:</v-subheader>

        <v-textarea
          v-model="office_notes"
          label="Approve/Deny Notes"
          hint="Add any notes here pertaining to this application's approval/denial and/or shipping"
          outlined
          filled
        ></v-textarea>
        <v-text-field
          v-model="holter_id"
          validate-on-blur
          label="Holter ID"
          prepend-icon="mdi-numeric"
          hint="enter an identifier for the holter being given to the patient"
          outlined
          filled
        ></v-text-field>

        </v-form>
        </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="!title || (title && !locked)"
          color="red darken-1"
          text
          @click="approve(false)"
          :disabled="loading"
          :loading="loading"
        >
          Deny {{changed ? 'and update referral' : ''}}
        </v-btn>
        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="approved || denied">
          <template v-slot:activator="{ on, attrs }">
            <v-alert
                :color="approved ? 'green' : 'red'"
                dense
                outlined
                elevation="4"
                class="mb-0"
                v-bind="attrs"
                v-on="on"
              >
                <b>{{approved ? "APPROVED" : "DENIED"}}</b>
            </v-alert>
          </template>
          {{approval_details}}
        </v-tooltip>
        
        <v-spacer></v-spacer>
        <v-btn
          v-if="!title || (title && !locked)"
          color="green darken-1"
          text
          @click="approve(true)"
          :disabled="loading"
          :loading="loading"
        >
          Approve {{changed ? 'and update referral' : ''}}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</template>

<script>
/*
2do:
- modify address bar when opening an approval with the study ID (ie. heartbeat.com/joblist/approval/7 <- study ID )
- address the recipient field (it should be there even on pick-up)

future:
- provide feedback notification &/or message to doc/patient/etc once approved
- take care of reviewing a submission
- add "print shipping label" functionality

done:
- fix DOB format
- detangled from vuex state
- change buttons from approve/deny after approval & allow clicking off by pressing blurred area
- allow editing of request form
  - put locks on the outside of every field so they can approve or deny stuff
*/
function HCV(ohip) {
  ohip = ohip.replaceAll(' ', '').replaceAll('-', '')
  if (!ohip) {
    return {error: 'OHIP number is missing'}
  }
  if (! [10, 12].includes(ohip.length)) {
    return {error: 'invalid number of characters in OHIP number.  Please check the card and try again'}
  }
  if (!RegExp('\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d').test(ohip.substr(0,10))) {
    return {error: 'invalid OHIP number: first 10 characters should be digits.  Please check the card and try again'}
  }
  if (ohip.substr(10,12) && !RegExp('[a-z,A-Z][a-z,A-Z]').test(ohip.substr(10,12))) {
    return {error: 'invalid OHIP version code: last 2 characters should be letters.  Please check the card and try again'}
  }

  var bottom_sum = 0
  var position = 0
  for (const digit of ohip.substr(0,9)) {
    if (position % 2 === 0 ) {
      const partial_sum = (2 * parseInt(digit)).toString()
      for (const digit2 of partial_sum) {
        bottom_sum = bottom_sum + parseInt(digit2)
      }
    } else {
      bottom_sum = bottom_sum + parseInt(digit)
    }
    position = position + 1
  }
  const check_bit = 10 - parseInt(bottom_sum.toString().slice(-1))
  if (check_bit != parseInt(ohip[9])) {
    return {error: 'invalid OHIP number: at least 1 of the digits is incorrect.  Please check the card and try again'}
  }

  return {valid: true}
}
function DOB(dob) {
  if (! dob){
    return {error: 'Date of Birth is missing'}
  }
  const ymd = dob.split('-')
  if (ymd.length != 3) {
    return {error: 'Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (ymd[0].length != 4) {
    return {error: 'Invalid date format: please give full 4-digit year'}
  }
  const max_yr = parseInt(new Date().toISOString().substr(0, 10))
  const min_yr = parseInt(new Date().getFullYear()-125 + '-01-01')
  if (parseInt(ymd[0]) > max_yr || parseInt(ymd[0]) < min_yr ) {
    return {error: 'Year is out of bounds.  Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (parseInt(ymd[1]) > 12 || parseInt(ymd[1]) < 1 ) {
    return {error: 'Month is out of bounds.  Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (parseInt(ymd[2]) > 31 || parseInt(ymd[2]) < 1 ) {
    return {error: 'Day is out of bounds.  Please check the day and try again'}
  }
  return {valid: true}
}
let form_variables = {
  patient_name: '',
  patient_dob: null,
  patient_sex: '',
  patient_ohip: '',
  patient_phone: '',
  patient_email: '',

  reason: '',
  patient_medications: '',
  duration: '',

  shipping: {
    delivery: false,
    recipient: '',
    country: 'Canada',
    street_address1: null,
    street_address2: null,
    city: null,
    province: 'Ontario',
    zip: null,
  },

  primary_dr: '',
  referring_dr: '',
  dr_billing: '',
  dr_phone: '',
  dr_fax: '',
  dr_email: '',

  destinations: [],

  notes: ''
}
let approval_variables = {
  office_notes: '',
  holter_id: '',
  approval_details: ""
}

import Destination from "@/request/destination.vue"
import Shipping from "./shipcomponent.vue"
import axios from 'axios'

export default {
  components: {Destination, Shipping},
  props: {
    vmodel: Boolean,
    study_id: Number,
    title: String,
  },
  model: { prop: 'vmodel' },
  data: () => ({
    ...form_variables,
    ...approval_variables,
    vmodel_local: false,
    ip_address: '',
    start_date: '',
    locked: true,
    loading: true,
    changed: false,
    approved: false,
    denied: false,

    durations: ['1 day', '2 days', '3 days', '7 days', '14 days'],
    dob_activator: false,
    dobRules: [ v => !! DOB(v).valid || DOB(v).error ],
    ohipRules: [ v => !! HCV(v).valid || HCV(v).error ],
    emailRules: [
      v => !!v || 'E-mail is missing',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],

    response_title: '',
    response: '',
  }),
  computed: {
    recipients() { 
      return [...new Set([
        (this.referring_dr || this.primary_dr) ?
          this.patient_name + ' c/o ' + (this.referring_dr || this.primary_dr) :
          this.patient_name,
        this.referring_dr,
        this.primary_dr])].filter(item => item)
    },
    approval_title() {
      if (this.title) {return this.title}
      return 'Approve / Deny holter request'
    }
  },
  methods: {
    change() {
      this.changed = true
    },
    destination_change(index, payload){
      this.changed = true
      this.destinations[index].checked = payload.checked
      this.destinations[index].service = payload.delivery_method.split('-')[1]
      this.destinations[index].address = payload.address
      this.destinations[index].label   = payload.title
    },
    autocomplete_sex() {
      if (['m', 'ma','mal','male'].includes(this.patient_sex.toLowerCase())) {
        this.patient_sex = 'male'
      } else if (['f', 'fe', 'fem', 'fema', 'femal', 'female'].includes(this.patient_sex.toLowerCase())) {
        this.patient_sex = 'female'
      }
    },
    save_dob (dob) {
      this.$refs.dob_activator.save(dob)
    },
    approve(approval) {
      this.loading = true
      let payload = {
        study_id: this.study_id,
        approval: approval,
        office_notes: this.office_notes,
        holter_id: this.holter_id
      }

      if (this.changed) {
        console.log('changed:',this.changed)
        payload.request_form = {}
        for (const entry in form_variables) {
          payload.request_form[entry] = this[entry]
        }
      }
      
      axios
        .post(process.env.VUE_APP_API_URL + '/api/approval_store', payload)
        .then( (response) => {
          this.loading = false
          if (response.data.approval_id) {
            this.reset_input_fields()
            this.fetch_server_data()
            this.$store.dispatch('fetchJobs')
            if (response.error) {console.log(response)}
          } else {
            console.log('strange error, no approval ID', response)
          }
        })
        .catch(err =>{
          this.loading = false
          const error_msg = err.response ? err.response.statusText : null
          console.log('error', error_msg ? error_msg : 'unknown error', err)
        })
    },
    close() {
      this.$emit('input', false)
    },
    reset_input_fields() {
      this.update_input_fields(form_variables)
      this.locked = true
      this.approved = false
      this.denied = false
      this.approval_details = ''
    },
    update_input_fields(payload) {
      for (const prop in {...form_variables, ip_address:'', start_date:'', ...approval_variables}) {
        this[prop] = payload[prop]
      }
      if (!('study_id' in payload)) {return null}
      this.patient_dob = new Date(this.patient_dob).toISOString().slice(0,10)
      for (const dest in this.destinations) {
        this.destinations[dest].checked = !!this.destinations[dest].pending
      }
      this.destinations.push({id: this.destinations.length, checked: false, service: 'fax', address: '', label: ''})
      if ('approved' in payload) {
        this.office_notes = payload.approval_notes
        this.holter_id = payload.monitor_id
        if (payload.approved) {
          this.approval_details = "Approved "+payload.approval_date+ " by "+payload.approver
          this.approved = true}
        else {
          this.approval_details = "Denied "+payload.approval_date+ " by "+payload.approver
          this.denied = true
          }
      }
    },
    fetch_server_data() {
      this.loading = true
      axios
        .post(process.env.VUE_APP_API_URL + '/api/referral_fetch', {study_id: this.study_id})
        .then(response => {
          //race condition in update_input_fields' this.destinations.push calling destination change with this.changed=true & 3 lines down this.changed=false
          this.update_input_fields(response.data)
          this.loading = false
          this.changed = false
        })
        .catch(error => {
          console.log('error',error)
          this.loading = false
          this.changed = false
        });
    },
  },
  watch: {
    vmodel_local(vmodel_local) {
      if (vmodel_local != this.vmodel) {
        this.$emit('input', vmodel_local)
      }
    },
    vmodel(vmodel) {
      this.vmodel_local = vmodel
      this.reset_input_fields()
      if (vmodel) {
        this.fetch_server_data()
      }
    }
  },
}
</script>
