<template>
  <v-container fluid>
            <template v-slot:extension>
                <v-btn
                  color="green"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
                >
                  <v-icon>mdi-left-arrow</v-icon>
                </v-btn>
            </template>
    <v-card>
      <v-card-title>
        Cardiologist Report
        <v-spacer></v-spacer>
        <superchip 
          :title="duration"
          icon="mdi-timer-outline"
          :tip="'Holter monitor worn for ' + duration"
          color="orange"
        />
        <superchip 
          :title="start_date.short"
          icon="mdi-calendar-month"
          :tip="'test started on ' + start_date.long"
          color="blue"
        />
      </v-card-title>
      <v-card-subtitle>
        {{patient_name}}
        <!--v-icon
          color="pink"
          class="ma-2"
          text
          icon
        >
          mdi-human-female
        </v-icon-->
        <superchip 
          :icon="sex.icon"
          :tip="'Sex: ' + sex.title"
          :color="sex.color"
        />
        <superchip 
          :title="age + ' Years'"
          icon="mdi-cake-variant"
          :tip="'DOB: ' + dob"
          color="green"
        />
      </v-card-subtitle>

      <v-divider></v-divider>
      <v-card-actions>
        <superchip 
          v-if="primary_dr"
          :title="primary_dr"
          icon="mdi-stethoscope"
          tip="Primary Physician"
          color="purple"
        />
        <v-spacer></v-spacer>
        <superchip 
          v-if="referring_dr"
          :title="referring_dr"
          icon="mdi-account-arrow-right"
          tip="Referring Physician (click for referral)"
          color="purple accent-1"
          @click="show_referral = true"
        />
        <a 
          v-if="tech_report_url"
          :href='tech_report_url'
          target="_blank">
          <superchip 
            :title="technologist"
            icon="mdi-badge-account-outline"
            tip="Cardiac Technologist (click for report)"
            color="indigo"
          />
        </a>
        <TechChip 
          v-if="tech_user"
          :study_id="study_id"
        />
        <superchip 
          v-if="tech_user || (doc_user && flag)"
          :icon="!!flag ? 'mdi-flag' : 'mdi-flag-plus'"
          :tip="!!flag ? 'Abnormal report (click to mark as normal)' : 'Flag as abnormal report'"
          color="red"
          :outlined="!!!flag"
          @click="flag = !!!flag"
        />
        <v-spacer></v-spacer>
      </v-card-actions>
        
      
      <v-divider></v-divider>
      <v-card-text>
        <p v-if="tech_notes"><b>Technologist's Comments:</b> {{tech_notes}}</p>
        <p v-if="indication"><b>Indication(s):</b> {{indication}}</p>
        <div class="d-flex flex-wrap justify-space-around">
          <inputblock title="Impression" :text="impression" @change="impression = $event" />
          <inputblock title="Reason for Test" :text="reason" @change="reason = $event" />
          <inputblock title="Rhythms" :text="rhythm" @change="rhythm = $event" />
          <inputblock title="Rate Comments" :text="ratecomments" @change="ratecomments = $event" />
          <inputblock title="Atrial Arrhythmias" :text="atrial" @change="atrial = $event" />
          <inputblock title="Ventricular Arrhythmias" :text="ventricular" @change="ventricular = $event" />
          <inputblock title="SVT" :text="svt" @change="svt = $event" />
          <inputblock title="Bradyarrhythmias and Pauses" :text="bradyarrhythmias" @change="bradyarrhythmias = $event" />
          <inputblock title="Symptoms" :text="symptoms" @change="symptoms = $event" />
          <inputblock title="Unusual" :text="unusual" @change="unusual = $event" />
          <inputblock title="Interpretation" :text="interpretation" @change="interpretation = $event" />
          <inputblock title="Recommendations" :text="recommendation" @change="recommendation = $event" />
        </div>
        <v-textarea
          v-if="tech_user"
          v-model="notes_tech"
          label="Technologist's Comments"
          hint="For internal use only, will not be included in final report"
          outlined
          filled
          dense
        ></v-textarea>
        <v-textarea
          v-if="doc_user"
          v-model="notes"
          label="Doctor's Comments"
          hint="For internal use only, will not be included in final report"
          outlined
          filled
          dense
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>

        <v-spacer></v-spacer>
        <v-btn
          v-if="final_report_ready"
          @click="final_report"
          color="indigo"
        >
          View Final Report
        </v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="ready_to_send"
          label="Final Report Ready to Send"
          :disabled="!doc_user"
          class="pr-6"
        ></v-checkbox>
        <v-btn
          color="green darken-1"
          text
          @click="submit"
        >
          {{ready_to_send ? 'Save & Send' : 'Save Changes'}}
        </v-btn>

        <simplerdialog
          :dialog="success_dialog"
          title="Success!"
          :body="success_dialog_body"
          cancel="back to report"
          ok="back to joblist"
          @response="success_dialog_response"
          @dialog="success_dialog = $event"
        />
        <simplerdialog
          v-if="doc_user"
          :dialog="saved_dialog"
          title="Saved (but still needs review)"
          :body="saved_dialog_body"
          ok="back to report"
          @response="saved_dialog = false"
          @dialog="saved_dialog = $event"
        />
        <simplerdialog
          v-else
          :dialog="saved_dialog"
          title="Saved"
          :body="saved_dialog_body"
          cancel="back to report"
          ok="back to joblist"
          @response="saved_dialog_response"
          @dialog="saved_dialog = $event"
        />
        <simplerdialog
          :dialog="error_dialog"
          title="Error :("
          :body="error_dialog_body"
          cancel="back to report"
          ok="try again"
          @response="error_dialog_response"
          @dialog="error_dialog = $event"
        />

      </v-card-actions>
    </v-card>
    <Approval v-model="show_referral" :study_id="parseInt(study_id)" title="Patient Referral" />
  </v-container>
</template>

<script>

/*
2do:
- throw a breadcrumb / back button in there
- check if a report exist and if so populate it's data
- open referral on click (even if it's just the approval popup)

done:
- wire up the patient data with axios (+ write the API)
- insert sex into the referral page
- get the input blocks returning their data
- add the Dr notes section to the bottom
- get it saving properly
  - handle the dialog (3 messages only)
    - 1 for failure
    - 1 for success (but tell them they're not done)
    - 1 for success (report will be sent within 24 hours)
  - test the API
    - write the axios call

future:
- add a private field that will be saved in the system but not sent with the final report
  - can add "Include in report" checkboxes next to Dr Notes field & Tech comments field
- could change that checkbox in the bottom to a "send delay" button (1 of the options could be indefinitely)
- give the doc the option to read the final report 
- give the doc the option to change the time delay before sending
- create a page for editing the impressions/reasons/etc:
  - simple table with 2 rows: titles & values
  - drop down up top to choose the block you're working on (reasons, impressions, etc)
    - maybe even allow user to add & rename tables
  - save the results to the database to be loaded by this page via axios every doctor login
    - they can be reloaded when saved obviously
- if no referring physician, change primary physician chip to link to referral (instead of listing both)
*/

let success_dialog_body = "The report has been saved and is scheduled for delivery.\n\n"
let saved_dialog_body = "The report has been saved but requires review before delivery.  Once complete, check the box labelled \"Ready to Send\" in the lower left of the report then submit again.\n\n"
let error_dialog_body = "Report not saved!  The following error occurred:  \n\n"

import axios from 'axios'
import { mapState } from 'vuex';
import superchip from "@/joblist/doc_report/superchip.vue"
import inputblock from "@/joblist/doc_report/inputblock.vue"
import simplerdialog from "@/joblist/doc_report/simplerdialog.vue"
import Approval from "@/joblist/approval/approval.vue"
import TechChip from "./techchip.vue"

export default {
  components: {superchip, inputblock, simplerdialog, Approval, TechChip},
  props: ['study_id'],
  data: () => ({
    impression: '',
    reason: '',
    rhythm: '',
    ratecomments: '',
    atrial: '',
    ventricular: '',
    svt: '',
    bradyarrhythmias: '',
    symptoms: '',
    unusual: '',
    interpretation: '',
    recommendation: '',
    notes: '',
    notes_tech: '',
    flag: null,
    ready_to_send: null,
    
    patient_data: {
      duration: '',
      timestamp: '',
      patient_name: '',
      patient_sex: '',
      patient_dob: '',
      primary_dr: '',
      referring_dr: '',
      technologist: '',
      tech_notes: ''
    },
    success_dialog: false,
    success_dialog_body: success_dialog_body,
    saved_dialog: false,
    saved_dialog_body: saved_dialog_body,
    error_dialog: false,
    error_dialog_body: error_dialog_body,
    show_referral: false,

    final_report_ready: false
  }),
  computed: {
    ...mapState({
      user: state => state.login.user
    }),
    duration () {return this.patient_data.duration},
    start_date () { return {short: (this.patient_data.timestamp || '').slice(5,16), long: new Date(this.patient_data.timestamp)} },
    patient_name () {return this.patient_data.patient_name},
    sex () { 
      if (this.patient_data.patient_sex === 'female') {
        return {icon: 'mdi-human-female', title: 'Female', color: 'pink'}
      }
      if (this.patient_data.patient_sex === 'male') {
        return {icon: 'mdi-human-male', title: 'Male', color: 'cyan'}
      }
      return {icon: 'mdi-alien', title: this.patient_data.patient_sex, color: 'lime'}
    },
    age () {
      const miliseconds_per_yr = 31536000000
      const miliseconds_since_birth = new Date().getTime() - new Date(this.patient_data.patient_dob).getTime()
      return parseInt(miliseconds_since_birth/miliseconds_per_yr)
    },
    dob () {return (this.patient_data.patient_dob || '').slice(5,16)},
    primary_dr () {return this.patient_data.primary_dr},
    referring_dr () {
      if (this.patient_data.referring_dr) {
        return this.patient_data.referring_dr
      } else {
        return this.patient_data.primary_dr
      }
    },
    technologist () {return this.patient_data.technologist},
    tech_report_url() {
      if (this.patient_data.tech_report_date) {
        return process.env.VUE_APP_API_URL + "/api/tech_report_fetch/tech_report" + this.study_id + ".pdf" 
      } else {
        return ''
      }
    },
    tech_notes () {return this.patient_data.tech_notes},
    tech_user () {return [...this.user.write].includes(3)},
    doc_user () {return [...this.user.write].includes(5)},
    indication () {return this.patient_data.reason}
  },
  watch: {
    user () {
      if (this.user != null) {
        this.fetchStudy(this.study_id)
      }
      this.ready_to_send = this.doc_user
    }
  },
  methods: {
    fetchStudy(study_id) {
      axios.get(process.env.VUE_APP_API_URL + '/api/study_fetch/' + study_id)
         //.then(response => {
         .then(({ data }) => {
            this.patient_data = data
            for (const item of ['impression', 'rhythm', 'ratecomments', 'atrial', 'ventricular', 'svt', 'bradyarrhythmias', 
                                'symptoms', 'unusual', 'interpretation', 'recommendation']){
              if (!this[item] && data[item]) {
                this[item] = data[item]
              }
            }
            if (!this.reason && data['doc_reason']) {
              this.reason = data['doc_reason']
            } 
            if (!this.notes && data['doc_notes']) {
              this.notes = data['doc_notes']
            }
            if (!this.notes_tech && data['tech_notes']) {
              this.notes_tech = data['tech_notes']
            }
            if (!this.flag && data['abnormal']) {
              this.flag = true
            }
            if (data['ready_to_send']) {
              this.final_report_ready = true
            }
            if (data['tech_report_date']) {
              window.open(this.tech_report_url, '_blank', 'fullscreen=yes')
            }
          })
         .catch(error => {
            if (! error.response) {
              console.log('study fetch failure...')
              console.log(error)
            } else if (error.response.status === 401) {
              //this.$store.state.login.user = null
              this.$store.dispatch('login_dialog', true)
            } else {
              console.log('study fetch error...')
              console.log(error.response)
            }
         });
    },
    tech_report() {
      window.open(process.env.VUE_APP_API_URL + "/api/tech_report_fetch/" + this.study_id, '_blank', 'fullscreen=yes')
    },
    submit() {
      let payload = {}
      for (const item of ['impression', 'reason', 'rhythm', 'ratecomments', 'atrial', 'ventricular', 'svt',
                          'bradyarrhythmias', 'symptoms', 'unusual', 'interpretation', 'recommendation',
                          'study_id', 'notes', 'ready_to_send']){
        payload[item] = this[item]
      }
      if (this.tech_user) {
        for (const item of ['notes_tech', 'flag']){
          payload[item] = this[item]
        }
      }
      axios
        .post(process.env.VUE_APP_API_URL + '/api/doc_report_store', payload)
        .then(({ data }) => {
          if (data.delivery_time && data.doc_report_id > 0) {
            this.success_dialog_body = this.success_dialog_body + "Final Report will be delivered " + data.delivery_time
            this.success_dialog = true
          } else if (data.doc_report_id > 0){
            this.success_dialog_body = "The report has been saved and is ready for Cardiologist review."
            this.saved_dialog = true
          } else {
            this.error_dialog_body = this.error_dialog_body + "Unexpected Error.  Server Response: " + data
            this.error_dialog_body = this.error_dialog_body + ".\n\n Please try again or send these detail to tech support for help."
            this.error_dialog = true
          }
          if(data.final_report_id){
            this.final_report_ready = true
          }
        })
        .catch(err =>{
          console.log(err)
          if (err.response && err.response.status === 401) {
              //this.$store.state.login.user = null
              this.error_dialog_body = "You were not logged in.  Try again?"
              this.error_dialog = true
              this.$store.dispatch('login_dialog', true)
              return null
          }
          const error_msg = err.response ? err.response.statusText : err
          this.error_dialog_body = this.error_dialog_body + ".\n\n Contacting the server failed with the following error: " + error_msg
          this.error_dialog_body = this.error_dialog_body + ".\n\n  Try again?"
          this.error_dialog = true
        })
    },
    success_dialog_response(response_val){
      this.success_dialog_body = success_dialog_body
      this.success_dialog = false
      if (response_val) {
        this.$router.push({name: 'Job List'})
      }
    },
    saved_dialog_response(response_val){
      this.saved_dialog_body = success_dialog_body
      this.saved_dialog = false
      if (response_val) {
        this.$router.push({name: 'Job List'})
      }
    },
    error_dialog_response(response_val){
      this.error_dialog_body = error_dialog_body
      this.error_dialog = false
      if (response_val) {
        this.submit()
      }
    },
    final_report(){
      window.open(process.env.VUE_APP_API_URL + "/api/final_report_fetch/" + this.study_id)
    }
  },
  created() {
    this.ready_to_send = this.doc_user
    this.fetchStudy(this.study_id)
  }
}
</script>