import axios from 'axios'
axios.defaults.withCredentials = true

/*
2do:
- investigate removal of "this.state.login.user = null"
- cant seem to get clearInterval to work, next step would be to...
  - keep the joblist_refresher_active variable in here
  - put the creation & destroyer logic in joblist.vue
  - see if calling window.clearInterval or clearInterval in joblist.vue would work
*/

export default ({
  state: {
    jobs: [],
    studies_fetched: 100,

    raw_data_dialog: false,
    tech_report_dialog: false,
    lazy_var: 0,
    joblist_refresher: null,
    joblist_refresher_active: false
  },
  mutations: {
    SET_VARIABLE (state, payload) {
      state[payload.variable] = payload.value
    },
    UPDATE_JOBS (state, jobs) {
      state.jobs = jobs
    },
    SET_JOBLIST_REFRESHER (state, active) {
      if (this.state.login.user === null) {
        //console.log('logged out, not activating')
        return null
      }
      if (active) {
        //console.log('activating')
        state.joblist_refresher_active = true
        state.page_refresher = window.setInterval(() => {
          console.log('joblist refresh')
          this.dispatch('fetchJobs')
        }, 30*1000)
        //console.log('refresher output:',state.page_refresher, state.page_refresher._id)
      } /*else {
        //console.log('deactivating')
        clearInterval(state.joblist_refresher)
        //console.log('refresher output after destruction 1:',state.page_refresher, state.page_refresher._id)
        window.clearInterval(state.joblist_refresher)
        
        console.log('refresher output after destruction 2:',state.page_refresher, state.page_refresher._id)
        state.joblist_refresher = null
        state.joblist_refresher_active = false
      }*/
    }
  },
  actions: {
    activateJoblistRefresher({commit}) {
      this.dispatch('fetchJobs')
      //console.log('action attempt success')
      //console.log('activating... the rfr is', this.state.joblist.joblist_refresher_active)
      if (this.state.joblist.joblist_refresher_active === false) {
        //console.log('action attempt success')
        commit('SET_JOBLIST_REFRESHER', true)
      }
    },
    deactivateJoblistRefresher({commit}) {
      this.dispatch('fetchJobs')
      console.log('deactivating the rfr was', this.state.joblist.joblist_refresher_active)
      commit('SET_JOBLIST_REFRESHER', false)
      console.log('but is now', this.state.joblist.joblist_refresher_active)
    },
    set_var({ commit }, payload) {
      commit('SET_VARIABLE', payload)
    },
    fetchJobs({ commit }) {
      axios.post(process.env.VUE_APP_API_URL + '/api/joblist', {studies: this.state.joblist.studies_fetched})
         .then(response => {commit('UPDATE_JOBS', response.data )})
         .catch(error => {
          commit('UPDATE_JOBS', [])
          if (! error.response) {
            console.log('job fetch failure...')
            console.log(error)
          } else if (error.response.status === 401) {
            //this.state.login.user = null
            this.dispatch('login_dialog', true)
          } else {
            console.log('job fetch error...')
            console.log(error.response)
          }
         });
    },
    jobFetchLimit({ commit }, studies) {
      commit('SET_VARIABLE', {variable: 'studies_fetched', value: studies})
      this.dispatch('fetchJobs')
    }
  }
});
