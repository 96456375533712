import axios from 'axios'

export default ({
  state: {
    approval_dialog: false,
    referral_data: null,
    approval_id: null
  },
  mutations: {
    UPDATE_REFERRAL (state, data) {
      state.referral_data = data
    },
    APPROVAL_DIALOG (state, newval) {
      state.approval_dialog = newval
    },
    APPROVAL_ID (state, newval) {
      state.approval_id = newval
    }
  },
  actions: {
    fetchReferral({ commit }, form_data) {
      axios.post(process.env.VUE_APP_API_URL + '/api/referral_fetch', form_data)
         .then(response => {commit('UPDATE_REFERRAL', response.data)
                            return response.data})
         .catch(error => {'lising error:', console.log(error.response)});
    },
    approval_dialog ({ commit }, newval) {
      commit('APPROVAL_DIALOG', newval)
    },
    approve({ commit }, form_data) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/api/approval_store', form_data)
        .then(({ data }) => {
          if (data.approval_id) {
            commit('APPROVAL_ID', data.approval_id)
            return data
          } else {
            return {error: 'Request server responded but did not provide an approval ID.', ...data }
          }
        })
        .catch(err =>{
          //console.log('dispatch_error:',err.response)
          //return {test_id: 7}
          const error_msg = err.response ? err.response.statusText : null
          return {error: error_msg ? error_msg : 'unknown error', ...err }
        })
      
    }
  }
});
