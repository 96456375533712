<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-file-edit</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in action_list"
        :key="index"
        @click="change(item)"
      >
      <v-icon>{{ item.icon }}</v-icon>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const icons = {
  approve: 'mdi-stamper',
  upload_raw_data: 'mdi-cloud-upload',
  download_raw_data: 'mdi-cloud-download',
  //upload_tech_report: 'mdi-file-download',
  download_tech_report: 'mdi-file-download',
  cardio_report: 'mdi-clipboard-pulse',
  final_report: 'mdi-file-star-outline'
}
const names = {
  approve: 'Approval',
  upload_raw_data: 'Upload Raw Data',
  download_raw_data: 'Download Raw Data',
  //upload_tech_report: 'Tech Report (Upload)',
  download_tech_report: 'Tech Report (Download)',
  cardio_report: 'Cardiology Report',
  final_report: 'Final Report'
}
export default {
  props: {
    actions: Array,
    patient: Object
  },
  computed: {
    action_list () {
      let output = []
      for (const action of this.actions) {
        output.push({name: names[action], icon: icons[action]})
      }
      return output
    }
  },
  data () {
    return {
      action: null
    }
  },
  methods: {
    change(item) {
      //console.log('change', item, this.study_id)
      if (item.name === 'Approval') {
        this.$emit('approve', this.patient.study_id)
      } else if (item.name === 'Upload Raw Data') {
        this.$emit('raw_upload', this.patient)
      } else if (item.name === 'Download Raw Data') {
        window.open(process.env.VUE_APP_API_URL + "/api/raw_data_fetch/" + this.patient.study_id)
      } else if (item.name === 'Tech Report (Upload)') {
        this.$emit('tech_upload', this.patient)
      } else if (item.name === 'Tech Report (Download)') {
        window.open(process.env.VUE_APP_API_URL + "/api/tech_report_fetch/" + this.patient.study_id)
      } else if (item.name === 'Cardiologist Report') {
        this.$router.push({name: 'report', params: {study_id: this.patient.study_id} })
      } else if (item.name === 'Final Report') {
        window.open(process.env.VUE_APP_API_URL + "/api/final_report_fetch/" + this.patient.study_id)
      }
    }
  }
}
</script>