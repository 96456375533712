import Vue from "vue"
import router from "@/router.js"
import store from "@/state.js"
import vuetify from "@/plugins/vuetify.js"

import Home from "@/Home.vue"

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Home)
}).$mount('#app')
