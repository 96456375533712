<template>

<v-row justify="center">
  <v-dialog
    v-model="raw_data_dialog"
    :persistent="! uploaded"
    width="800"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Upload Holter raw data
        <v-spacer></v-spacer>
        <v-btn
          text
          color="red darken-1"
          @click="raw_data_dialog = false"
          :disabled="cancel_token != null">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text height="80%">
        <v-form 
          ref="raw_data_form"
          lazy-validation
          @submit.prevent="upload"
        >
        
          <v-list-item-content>
            <v-list-item-title class="title">
              {{patient_data.patient_name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{ohip_formatted}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-file-input
            v-model="file"
            color="deep-purple accent-4"
            label="Upload Holter Raw Data"
            prepend-icon="mdi-paperclip"
            @change="upload"
            outlined
            filled
            :disabled="uploaded"
          ></v-file-input>

        </v-form>

      <v-progress-linear
        :value="uploadPercentage"
        color="blue-grey"
        height="25"
        :buffer-value="cancel_token != null ? 0 : 100"
        striped
        :stream="cancel_token != null"
      >
        <template v-slot="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>

      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="cancel_upload"
          :disabled="uploaded"
        >
          Cancel upload
        </v-btn>
        
        <v-spacer></v-spacer>

        <v-alert 
            v-if="uploaded"
            type="success"
            dense
            class="mb-0"
          >
            Upload Complete!
        </v-alert>
        <v-btn
          color="green darken-1"
          text
          @click="raw_data_dialog = false"
          :disabled="cancel_token != null"
          :loading="cancel_token != null"
        >
          Done
        </v-btn>

        <!--simpledialog
          dialog_name="success_dialog"
          :title="response_title"
          :body="response"
          cancel="back to application"
          ok="back to joblist"
          @response="success_response"
        /-->

      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>
</template>

<script>
/*
2do:
- only show the hint if wrong filename: "locate the flash.dat file in the correct drive"

afterwards:

Engineering notes:

future:

*/

import axios from 'axios'

export default {
  //components: {destination, shipping, simpledialog},
  props: {
    patient_data: Object
  },
  data: () => ({
    file: null,
    submitting: false,
    uploadPercentage: 0,
    cancel_token: null,

    uploaded: false
  }),
  computed: {
    raw_data_dialog: {
      get() {
        return this.$store.state.joblist.raw_data_dialog
      },
      set(newval) {
        this.$store.dispatch('set_var', {variable: 'raw_data_dialog', value: newval})
      }
    },
    ohip_formatted() {
      const ohip = this.patient_data.patient_ohip || ''
      return (ohip.slice(0,4)  + ( ohip.slice(4,7).length > 0   ? ' - ' : '')) +
             (ohip.slice(4,7)  + ( ohip.slice(7,10).length > 0  ? ' - ' : '')) +
             (ohip.slice(7,10) + ( ohip.slice(10,12).length > 0 ? ' - ' : '')) +
              ohip.slice(10,12)
    }
  },
  methods: {
    upload() {
      this.uploadPercentage = 0
      let formData = new FormData()
      if (this.cancel_token) {
        this.cancel_token.cancel("Upload restarted")
      }

      if (! this.file) { return null}
      formData.append('file', this.file)
      formData.append('study_id', this.patient_data.study_id)
      
      this.cancel_token = axios.CancelToken.source()
      axios.post(process.env.VUE_APP_API_URL + '/api/raw_data_store',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          cancelToken: this.cancel_token.token,
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(this)
        }
      ).then(({ data }) => {
        if (! data.raw_data_id > 0) {
          console.log('unexpected data:', data)
        }
        this.uploaded = true
        this.cancel_token = null
        this.$store.dispatch('fetchJobs')
      })
      .catch(function(){
        console.log('FAILURE!!')
        this.cancel_token = null
      }.bind(this))
    },
    cancel_upload() {
      if (this.cancel_token != null) {
        this.cancel_token.cancel("Upload aborted by user")
      }
      this.cancel_token = null
    },
    save_dob (dob) {
      this.$refs.dob_activator.save(dob)
    },
  },
  created() {
    this.raw_data_dialog = true
  }
}
</script>
