<template>
  <v-dialog
    v-model="dialog_model"
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{title}}
      </v-card-title>

      <v-card-text>
        {{body}}
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="cancel"
          color="primary darken-1"
          text
          @click="response(false)"
        >
          {{cancel}}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          v-if="ok"
          color="primary"
          @click="response(true)"
        >
          {{ok}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
usage instructions:
<simplerdialog
  :dialog="error_dialog"
  title="Error :("
  :body="error_dialog_body"
  cancel="back to report"
  ok="try again"
  @response="error_dialog_response" <- emits true when ok is pressed & false when cancel is pressed
  @dialog="error_dialog = $event"
/>

2do:
- make it prettier ()
- pass an optional icon prop so it can display a big centered icon (like Wave does)
  - animate that icon
- allow passing an array of text instead of just 1 text item for a better laid-out body

*/

export default {
  props: {
    dialog: Boolean,
    title: String,
    body: String,
    cancel: String,
    ok: String
  },
  data: () => ({
    dialog_model: false
  }),
  //computed: {
  //  dialog: {
  //    get() {
  //      return this.$store.state.request[this.dialog_name]
  //    },
  //    set(newval) {
  //      this.$store.dispatch('set_var', {variable: this.dialog_name, value: newval})
  //    }
  //  }
  //},
  watch: {
    dialog(newval) {
      this.dialog_model = newval
    },
    dialog_model(newval) {
      this.$emit('dialog', newval)
    }
  },
  methods: {
    response(value) {
      this.$emit('response', value)
    }
  }
}
</script>