<template>
  <v-container>
    <v-card>
      <v-card-title>
        Completed Holters
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <duration @change="fetch_summary" />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="docs"
        class="elevation-1"
        hide-default-footer
      >
        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th v-for="duration in nonzero_durations" :key="duration">{{tally[duration]}}</th>
            <!--th>{{tally['1 day']}}</th>
            <th>{{tally['2 day']}}</th>
            <th>{{tally['3 day']}}</th>
            <th>{{tally['7 day']}}</th>
            <th>{{tally['14 day']}}</th>
            <th>{{tally['other']}}</th-->
            <th>{{tally['total']}}</th>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
/*
2do:
- fix the body.append area so that it doesn't mess with the mobile view of the table:
  - look at all the templates from the docs
  - find an example of a default row
  - use that same style in the appended row
  - then post a reply here: https://stackoverflow.com/questions/59053219/vuetify-datatable-add-totals-row
*/
const durations = ['1 day', '2 day', '3 day', '7 day', '14 day', 'other']
import duration from "./duration.vue"
import { mapState } from 'vuex';
import axios from 'axios'
export default {
  components: {duration},
  data () {
    return {
      headers: [
        { text: '', value: 'name' },
        { text: '1 Day', value: '1 day' },
        { text: '2 Day', value: '2 day' },
        { text: '3 Day', value: '3 day' },
        { text: '7 Day', value: '7 day' },
        { text: '14 Day', value: '14 day' },
        { text: 'other', value: 'other' },
        { text: 'Total', value: 'total' },
      ],
      docs: [
        {
          name: 'Dhssraj Singh',
          '1 day': 1,
          '2 day': 2,
          '3 day': 5,
          '7 day': 0,
          '14 day': 15,
          total: 23
        },{
          name: 'Roland Mikhail',
          '1 day': 3,
          '2 day': 1,
          '3 day': 2,
          '7 day': 4,
          '14 day': 15,
          total: 25
        }
      ],
      tally: {},
      nonzero_durations: durations,
      start_date: '',
      end_date: ''
    }
  },
  computed: {
    ...mapState({ user: state => state.login.user }),
    //bug to be reported:
    //nonzero_durations() {
    //  return durations.filter(v => this.tally[v] > 0)
    //}
  },
  watch: {
    docs() {
      this.tally_summary()
      this.nonzero_durations = durations.filter(v => this.tally[v] > 0)
    },
    user(user) {
      if (user) {
        this.fetch_summary(this.start_date, this.end_date)
      }
    }
  },
  methods: {
    fetch_summary(start_date, end_date) {
      this.start_date = start_date
      this.end_date = end_date
      axios.post(process.env.VUE_APP_API_URL + '/api/billing', {start_date:start_date, end_date:end_date})
         .then(({ data }) => {
            this.docs = data
          })
         .catch(error => {
            if (! error.response) {
              console.log('billing fetch failure...')
              console.log(error)
            } else if (error.response.status === 401) {
              this.docs = []
              this.$store.dispatch('login_dialog', true)
            } else {
              console.log('billing fetch error...')
              console.log(error.response)
            }
         });
    },
    tally_summary() {
      this.tally.total = 0
      for (const duration of durations) {
        this.tally[duration] = this.docs.reduce((total, v) => total + parseInt(v[duration])||0, 0)
        this.tally.total += this.tally[duration]

        const header_id = this.headers.findIndex(v => v.value === duration)
        if (this.tally[duration] === 0){ 
          this.headers[header_id].align = ' d-none' 
        } else if (this.headers[header_id].align) {
          delete this.headers[header_id].align
        }
      }
    }
  },
  created() {
    this.tally_summary()
    this.fetch_summary()
  }
}
</script>