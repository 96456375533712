<template>
  <v-dialog
    v-model="vmodel_local"
    scrollable
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Modify Pre-defined "{{title}}" Comments
      </v-card-title>

      <v-card-text class="pt-2">
        <div v-for="(option, index) in options" :key="index">
          <ModifyableOption :full_text="option.full_text" :short_hand="option.short_hand" @change="change(index, $event)" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary darken-1"
          text
          @click="revert();vmodel_local=false"
        >
          cancel
        </v-btn>

        <v-spacer></v-spacer>

        <v-alert
          v-if="error_message"
          type="error"
        >
        {{error_message}}
        </v-alert>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="save()"
          :loading="saving"
        >
          save changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ModifyableOption from "./modifyableoption.vue"
import axios from 'axios'
export default {
  components: { ModifyableOption },
  props: {
    vmodel: Boolean,
    title: String,
    modifyable_options: Array
  },
  model: { prop: 'vmodel' },
  data: () => ({
    vmodel_local: false,
    options: [],

    saving: false,
    error_message: '',
  }),
  watch: {
    vmodel_local(vmodel_local) {
      if (vmodel_local != this.vmodel) {
        this.$emit('input', vmodel_local)
      }
    },
    vmodel(vmodel) {
      this.error_message = ''
      this.vmodel_local = vmodel
      if (vmodel) {
        this.options = this.modifyable_options
      }
    },
    options() {
      this.watch_last_options()
    }
  },
  methods: {
    change(index, {short_hand, full_text}) {
      this.error_message = ''
      this.options[index].short_hand = short_hand
      this.options[index].full_text = full_text
      this.watch_last_options()
    },
    watch_last_options() {
      if (this.options[this.options.length-1].short_hand || this.options[this.options.length-1].full_text) {
        this.options.push({short_hand:'', full_text:''})
      }
    },
    save() {
      this.saving = true
      if (this.invalid_check()){
        this.saving = false
        return null
      } 

      axios
        .post(process.env.VUE_APP_API_URL + '/api/doc_report_options_store', {options: this.options, title: this.title})
        .then(response => {
          this.saving = false
          if (!response.data) { console.log('there was a problem') }
          this.vmodel_local = false
          this.$emit('change')
        })
        .catch(error => {
          this.saving = false
          console.log('error', error)
        });
    },
    invalid_check() {
      this.options = this.options.filter(v => !!(v.full_text && v.short_hand))
      const keys = this.options.slice(0, this.options.length - 1).map(v => v.short_hand)
      //const vals = this.options.slice(0, this.options.length - 1).map(v => v.full_text)
      const key_set = new Set(keys)
      //const val_set = new Set(vals)
      //if (key_set.has('') || val_set.has('')) {
      //  this.error_message = 'Empty cell exists: cannot save.  Only last pair of cells can be empty'
      //  return true
      //}
      if (key_set.size != keys.length) {
        this.error_message = 'duplicate menu items forbidden: cannot save'
        return true
      }
      return false
    },
    revert() {
      this.$emit('change')
    }
  }
}
</script>