<template>
  <v-form 
    ref="raw_data_form"
    lazy-validation
    @submit.prevent="upload"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          color="teal"
          text-color="white"
          class="ma-2"
          v-bind:class="{ 'pr-0': !title }"
          @click="tech_report_upload()"
        >
          <v-icon left>
            {{icon}}
          </v-icon>
          {{title}}
        </v-chip>
      </template>
      <span>{{tip}}</span>
    </v-tooltip>
    <v-file-input
      v-model="file"
      ref="tech_report_uploader"
      class="d-none"
      @change="upload"
    ></v-file-input>
  </v-form>
</template>

<script>
import axios from 'axios'
export default {
  props: ['study_id'],
  data: () => ({
    file: null,
    cancel_token: null,
    uploadPercentage: 0,
    uploaded: false,
  }),
  computed: {
    title() {
      if (this.cancel_token) {
        return this.uploadPercentage + '%'
      } else {
        return ''
      }
    },
    icon() {
      if (this.uploaded) {
        return 'mdi-check-circle'
      } else if (this.cancel_token) {
        return 'mdi-stop-circle'
      } else {
        return 'mdi-file-upload'
      }
    },
    tip() {
      if (this.uploaded) {
        return 'Tech report uploaded.  Click to replace/re-upload'
      } else if (this.cancel_token) {
        return 'Upload in progress - click to cancel'
      } else {
        return "upload technologist's report"
      }
    },
  },
  methods: {
    tech_report_upload() {
      if (this.cancel_token) {
        this.cancel_upload()
      } else {
        this.$refs.tech_report_uploader.$refs.input.click()
      }
    },
    upload() {
      this.uploadPercentage = 0
      let formData = new FormData()
      if (this.cancel_token) {
        this.cancel_token.cancel("Upload restarted")
      }

      if (! this.file) { return null}
      formData.append('file', this.file)
      formData.append('study_id', this.study_id)
      
      this.cancel_token = axios.CancelToken.source()
      axios.post(process.env.VUE_APP_API_URL + '/api/tech_report_store',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          cancelToken: this.cancel_token.token,
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(this)
        }
      ).then(({ data }) => {
        if (! data.report_id > 0) {
          console.log('unexpected data:', data)
        }
        this.uploaded = true
        this.cancel_token = null
      })
      .catch(function(){
        console.log('FAILURE!!')
        this.cancel_token = null
      }.bind(this))
    },
    cancel_upload() {
      if (this.cancel_token != null) {
        this.cancel_token.cancel("Upload aborted by user")
      }
      this.cancel_token = null
    },
  }
}
</script>