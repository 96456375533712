export default({
  state: {
    navleft_open: false,
    cheese: 'navcheddar'
  },
  mutations: {
    NAVLEFT_OPEN (state, newval) {
      state.navleft_open = newval
    }
  },
  actions: {
    navleft({ commit }, newval) {
      commit('NAVLEFT_OPEN', newval)
    }
  }
});