import axios from 'axios'

export default({
  state: {
    delivery: true,
    recipient: null,
    country: 'Canada',
    street_address1: null,
    street_address2: null,
    city: null,
    province: 'Ontario',
    zip: null,

    validation_dialog: false,
    success_dialog: false,
    retry_dialog: false,
    study_id: null
  },
  mutations: {
    SET_VARIABLE (state, payload) {
      state[payload.variable] = payload.value
    },
    SET_STUDY_ID (state, id) {
      state.study_id = id
    }
  },
  actions: {
    set_var({ commit }, payload) {
      commit('SET_VARIABLE', payload)
    },
    request_holter({ commit }, form_data) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/referral_store', form_data)
        .then(({ data }) => {
          if (data.study_id) {
            commit('SET_STUDY_ID', data.study_id)
            return data
          } else {
            return {error: 'Request server responded but did not provide a study ID.', ...data }
          }
        })
        .catch(err =>{
          //console.log('dispatch_error:',err.response)
          //return {test_id: 7}
          const error_msg = err.response ? err.response.statusText : null
          return {error: error_msg ? error_msg : 'unknown error', ...err }
        })
      
    }
  }
});