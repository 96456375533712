<template>
  <v-container>
    <v-switch
      v-model="delivery"
      :label="`Delivery`"
      :readonly = "readonly"
      @change="change"
    ></v-switch>
    <v-switch
      v-model="pickup"
      :label="`Pick-up`"
      :readonly = "readonly"
    ></v-switch>

    <v-combobox
      v-model="recipient"
      :rules="[v => !!v || 'Recipient is missing']"
      label="Recipient"
      prepend-icon="mdi-account"
      :hint="delivery ? 'The name that the package should be addressed to' : 'The name of the person collecting the package from The Windsor Heart Institute'"
      :items="recipients"
      required
      :menu-props="{ nudgeBottom: 25 }"
      outlined
      filled
      :readonly = "readonly"
      @change="change"
    ></v-combobox>
    <!--v-text-field
      v-model="recipient"
      label="Recipient"
      hint="The name that the package should be addressed to"
      prepend-icon="mdi-account"
      required
      outlined
      filled
      elevation=5
    ></v-text-field-->

    <v-autocomplete
      v-model="country"
      :items="countries"
      label="Country"
      outlined
      filled
      :disabled="pickup"
      :readonly = "readonly"
      @change="change"
    >
      <template v-slot:prepend>
        <country-flag :country="flags[country] || ''" />
      </template>
    </v-autocomplete>

    <v-text-field
      v-model="street_address1"
      :rules="[v => !!v || 'Street address for delivery is missing']"
      label="Street Address"
      prepend-icon="mdi-home"
      required
      outlined
      filled
      :disabled="pickup"
      :readonly = "readonly"
      @change="change"
    ></v-text-field>

    <v-text-field
      v-model="street_address2"
      label="Apt / Suite / Unit"
      prepend-icon="mdi-domain"
      outlined
      filled
      :disabled="pickup"
      :readonly = "readonly"
      @change="change"
    ></v-text-field>

    <v-text-field
      v-model="city"
      :rules="[v => !!v || 'City for delivery is missing']"
      label="City"
      prepend-icon="mdi-map-marker"
      outlined
      filled
      :disabled="pickup"
      :readonly = "readonly"
      @change="change"
    ></v-text-field>

    <v-card
      flat
      class="d-flex flex-wrap align-content-center flex-grow-1"
    >
      <v-card
        flat
        class="flex-grow-1 flex-shrink-1"
      >
        <v-autocomplete
          v-model="province"
          :items="states[country]"
          prepend-icon="mdi-earth"
          outlined
          filled
          :disabled="pickup"
          :readonly = "readonly"
          @change="change"
        >
          <template v-slot:label v-if="country==='Canada'">Province</template>
          <template v-slot:label v-else>State</template>
        </v-autocomplete>
      </v-card>
      
      <v-card
        flat
        class="flex-grow-1 flex-shrink-1"
      >
        <v-text-field
          v-model="zip"
          prepend-icon="mdi-just-a-spacer"
          :rules="[v => !!v || ((country === 'Canada') ? 'Postal Code for delivery is missing' : 'Zip Code for delivery is missing')]"
          outlined
          filled
          :disabled="pickup"
          :readonly = "readonly"
          @change="change"
        >
          <template v-slot:label v-if="country==='Canada'">Postal Code</template>
          <template v-slot:label v-else>Zip Code</template>
        </v-text-field>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
/*
2do:
- watch "delivery" & "Country" variables so that validation rules can be updated accordingly

future features:
- get postal code rules to re-update when country changes
- remove all validation errors when pick-up is selected
- watch recipients array for changes & make the recipient value change if not touched yet
- use location of user to autofill any city/state/etc
- use Google maps API to auto-complete address
*/
const form_variables = {
  delivery: false,
  recipient: '',
  country: 'Canada',
  street_address1: null,
  street_address2: null,
  city: null,
  province: 'Ontario',
  zip: null,
}

import CountryFlag from 'vue-country-flag'
export default {
  components: {
      CountryFlag
  },
  props: {
    vmodel: Object,
    recipients: Array,
    readonly: Boolean
  },
  model: { prop: 'vmodel' },
  data() {
    return {
      countries: ['Canada', 'United States of America'],
      flags: {'Canada': 'ca', 'United States of America': 'us'},
      states: {'Canada': ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Northwest Territories', 'Nunavut', 'Yukon'],
               'United States of America': ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]
      },
      ...form_variables
    }
  },
  computed: {
    pickup: {
      get() {
        return !this.delivery
      },
      set(newval) {
        this.delivery = !newval
      }
    }
  },
  watch: {
    vmodel(vmodel) {
      for (const prop in form_variables) {
        this[prop] = vmodel[prop]
      }
    }
  },
  methods: {
    change() {
      let vmodel = {}
      for (const prop in form_variables) {
        vmodel[prop] = this[prop]
      }
      this.$emit('input', vmodel)
      this.$emit('change', vmodel)
    }
  },
  created() {
    for (const prop in form_variables) {
      this[prop] = this.vmodel[prop]
    }
  }
}
</script>

<style scoped>
  .disabled >>> .v-text-field__slot input {
    text-decoration: line-through
  }
</style>