<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-carousel cycle>
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Patch Holter Monitor Service
        </h1>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <v-btn elevation="2" color="primary" :to="{name: 'Request'}">Request Holter</v-btn>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >

        <v-row justify="center">
          <p class="text-justify">
            With myKardio service, patients can be referred for a 72 Hour, 7 Day or 14 Day Holter ECG from the comfort of their own homes.
            <br>
            The compact and waterproof device is delivered via courier, with clear instructions included for the patient to be able to carry out the test themselves. The courier then returns at the end of the required period to pick up the device containing the patient’s recording. Alternatively the holter can be installed by the referring physician or clinic.
          </p>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <v-row justify="center">
          <p class="text-justify">
            Once the device arrives at our centre, it will be analyzed by our team of expert cardiac technologists who will produce a report which will be forwarded to a cardiologist for diagnostic, the results will be sent to the patient’s GP and/or specialist.
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Welcome',
  data () {
    return {
      items: [
        { src: require('@/assets/welcome/On chest.png') },
        { src: require('@/assets/welcome/heart pule blue - centered.jpg') },
        { src: require('@/assets/welcome/ekg-heart-wave_42077-631.jpg') },
        { src: require('@/assets/welcome/small mailbox.jpg') },
        { src: require('@/assets/welcome/arrhythmia.jpg') },
        { src: require('@/assets/welcome/Holter wet.png') },
        { src: require('@/assets/welcome/blue body ted heart.jpg') },
      ],
    }
  },
}
</script>