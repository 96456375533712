<template>
  
    <v-card><v-form @submit.prevent="login">
      <v-card-title class="headline grey lighten-2">
        Login
        <v-spacer></v-spacer>
        <v-btn text color="red darken-1" @click="login_dialog = false">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        
          <v-text-field 
            v-model="email" 
            type="email" 
            name="email" 
            value 
            label="Email" 
            prepend-icon="mdi-account-circle"
          />
          <v-text-field 
            v-model="password" 
            type="password" 
            name="password" 
            value 
            label="password" 
            prepend-icon="mdi-lock"
            append-icon="mdi-eye-off"
          />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="login_dialog = false"
        >
          cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          type="submit"
          name="button"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-form></v-card>
  
</template>

<script>
/*
2do:
- deal with server offline, login failed
- add @mousedown & @mouseup events with an appended eye to handle showing the password
*/
//import routes from '@/router.js'

export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    login_dialog: {
      get() {
        return this.$store.state.login.login_dialog
      },
      set(newval) {
        this.$store.dispatch('login_dialog', newval)
      }
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password
      })
      .then( () => {
        if ( ! this.$route.meta.requiresAuth) {
          this.$router.push({name: 'Job List'})
        }
        if (this.$store.state.login.user) {
          this.login_dialog = false
        }
      })
    }
  }
}
</script>
