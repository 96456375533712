<template>
  <v-navigation-drawer
    v-model="navleft_open"
    absolute
    temporary
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          myKardio
        </v-list-item-title>
        <v-list-item-subtitle>
          data-driven heart strength
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="link in links"
        :key="link.name"
        :to="{name: link.name}"
        link
        exact
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ link.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import routes from '@/router.js'

export default {
  computed: {
    ...mapState({
      is_navleft_open: state => state.nav.navleft_open,
      user: state => state.login.user
    }),
    navleft_open: {
      get() {
        return this.$store.state.nav.navleft_open
      },
      set(newval) {
        this.$store.dispatch('navleft', newval)
      }
    },
    links() {
      //console.log(this.user)
      const guest_user = this.user === null ? true : false
      const billing_user = guest_user ? false : this.user.read.includes(6)
      return routes.options.routes.filter(item => {
        const restricted_area = item.requiresAuth
        const billing_area = item.name === 'Billing'
        if (! item.navlink) { return false }
        if (guest_user && ! restricted_area) { return true }
        if (billing_area && billing_user) {return true}
        if (billing_area) {return false}
        if (! guest_user) { return true }
      })
    }
  }
}
</script>