<template>
  <v-tooltip top :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        :color="color"
        :outlined="outlined"
        :text-color="outlined ? color : 'white'"
        :label="false"
        class="ma-2"
        v-bind:class="{ 'pr-0': !title }"
        @click="click"
      >
        <v-icon left>
          {{icon}}
        </v-icon>
        {{title}}
      </v-chip>
    </template>
    <span>{{tip}}</span>
  </v-tooltip>
</template>

<script>
/*
2do:
- only have pointer if click is active
*/
export default {
  props: {
    color: String,
    title: String,
    tip: String,
    icon: String,
    outlined: Boolean
  },
  computed: {
    disabled() {
      return this.tip ? false : true
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  },
  //created () {
  //  if (this.tip ) {
  //    console.log('tippin')
  //  }
  //}
}
</script>