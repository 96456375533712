<template>
  <v-responsive class="flex-grow-1" min-width="300px">
    <v-autocomplete
      :items="items"
      :label="title"
      dense
      multiple
      :menu-props="{ 'top': true }"
      outlined
      filled
      @change="update_body"
    >
      <template v-slot:selection=""></template>
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item @click="modify">
          <v-list-item-avatar>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              Modify this list
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!--template v-slot:prepend-item>
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="selectedFruits.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template-->
    </v-autocomplete>
    <v-textarea
      class="mt-n7"
      v-model="body"
      outlined
      filled
      dense
      tight-line
      height="170px"
      @change="change"
    ></v-textarea>
    <ModifyInputBlock 
      v-model="modify_dialog" 
      :title="title" 
      :modifyable_options="modifyable_options"
      @change="reload_options()" />
  </v-responsive>
</template>

<script>
/*
2do:
- add tooltips to menu items by using the "slots" entry here: https://vuetifyjs.com/en/components/selects/#readonly
- figure out how to get  the menu to always open on the right unless the right is blocked then open on the left
  - and then tell this dude how I did it:
    https://stackoverflow.com/questions/52182230/how-to-change-direction-of-the-vuetify-menu-on-the-fly
*/
import ModifyInputBlock from "./modifyinputblock.vue"
import axios from 'axios'
import { mapState } from 'vuex';
export default {
  components: { ModifyInputBlock },
  props: {
    title: String,
    text: String
  },
  data: () => ({
    options: {},
    items: [],
    body: '',
    previous_items: [],
    modify_dialog: false,
    modifyable_options: [],
  }),
  computed: {
    ...mapState({
      user: state => state.login.user
    }),
  },
  watch: {
    text(value) {
      this.body = value
    },
    user () {
      if (this.user != null) {
        this.reload_options()
      }
    },
  },
  methods: {
    update_body(a) {
      if (a.length > this.previous_items.length) {
        const added_item = a[a.length-1]
        this.previous_items.push(added_item)
        this.body = this.body + this.options[added_item] + '  '
      } else {
        const deleted_item = this.previous_items.filter(x => !a.includes(x))[0]
        this.previous_items = a
        this.body = this.body.replace(this.options[deleted_item] + '  ', '')
      }
      this.change()
    },
    modify() {
      //close menu
      this.modify_dialog = true
    },
    change() {
      this.$emit('change', this.body)
    },
    reload_options() {
      axios
        .post(process.env.VUE_APP_API_URL + '/api/doc_report_options_fetch', {title: this.title})
        .then(response => {
          this.process_options(response.data)
        })
        .catch(error => {
          console.log('error',error)
        });
    },
    process_options(response) {
      this.options = {}
      this.items = []
      for (const option of response) {
        this.items.push(option.short_hand)
        this.options[option.short_hand] = option.full_text
      }
      this.modifyable_options = response
    }
  },
  created() {
    this.reload_options()
  }
}
</script>
<style>
[tight-line] {
  line-height: 1rem !important;
  /*font-size: 16px*/
}
</style>