<template>
  <div class="d-flex flex-grow-1">
    <v-text-field
      v-model="date"
      label="Time Period"
      prepend-icon="mdi-calendar"
      readonly
      @click="modal_toggle"
    >
    </v-text-field>
    <v-dialog
      v-model="modal_month"
      width="290px"
    >
      <v-date-picker
        v-model="month"
        type="month"
        class="mt-4"
        min="2021-02"
        :max="next_month"
        scrollable
        @change="change"
      >
        <v-switch
          v-model="range_picker"
          label="Fine-pick date range"
        ></v-switch>
      </v-date-picker>
    </v-dialog>
    <v-dialog
      v-model="modal_days"
      width="290px"
    >
      <v-date-picker
        v-model="days"
        type="date"
        class="mt-4"
        scrollable
        range
        @change="change"
      >
        <v-switch
          v-model="range_picker"
          label="Fine-pick date range"
        ></v-switch>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
/*
2do:
- make the cursor in the date's text-field a pointer instead of text cursor
*/
export default {
  data () {
    return {
      month: new Date().toISOString().slice(0,10),
      days: [new Date().toISOString().slice(0,10), new Date().toISOString().slice(0,10)],
      modal_month: false,
      modal_days: false,
      range_picker: false
    }
  },
  watch: {
    range_picker(range_picker) {
      if (range_picker) {
        this.modal_month = false
        this.modal_days = true
      } else {
        this.modal_days = false
        this.modal_month = true
      }
    }
  },
  computed: {
    date() {
      if (this.range_picker) {
        return `${this.days[0]} through ${this.days[1]}`
      } else {
        var [yr, mo] = this.month.split('-')
        const mo_0 = new Date(yr, mo - 2, 1).toLocaleString('default', { month: 'short' })
        const yr_0 = new Date(yr, mo - 2, 1).toLocaleString('default', { year: '2-digit' })
        const mo_1 = new Date(yr, mo - 1, 1).toLocaleString('default', { month: 'short' })
        const yr_1 = new Date(yr, mo - 1, 1).toLocaleString('default', { year: '2-digit' })
        return `18-${mo_0}-${yr_0} through 17-${mo_1}-${yr_1}`
      }
    },
    next_month() {
      var [yr, mo] = this.month.split('-')
      return new Date(yr, mo).toISOString().slice(0,7)
    }
  },
  methods: {
    change() {
      if (this.range_picker) {
        var [yr_end, mo_end, day_end] = this.days[1].split('-')
        var [yr_start, mo_start, day_start] = this.days[0].split('-')
        this.$emit('change',
                    new Date(yr_start, mo_start-1, parseInt(day_start)),
                    new Date(yr_end, mo_end-1, parseInt(day_end)+1))
        this.modal_days = false
      } else {
        var [yr, mo] = this.month.split('-')
        this.$emit('change', new Date(yr, mo - 2, 18), new Date(yr, mo - 1, 18))
        this.modal_month = false
      }
    },
    modal_toggle() {
      if (this.range_picker) {
        this.modal_days = true
      } else {
        this.modal_month = true
      }
    }
  }
}
</script>