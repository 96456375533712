<template>
  <v-container>
    <v-card>
      <v-card-title>
        Job List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="jobs"
        :search="search"
        :multi-sort="false"
        class="elevation-1"
        :footer-props="{
          prevIcon: '',
          nextIcon: '',
          disablePagination: true,
          itemsPerPageText: 'Studies fetched',
          itemsPerPageOptions: [100, 1000, -1],
          pagination: {}
        }"
        :items-per-page.sync="studies_fetched"
      >
        <template v-slot:item.action="{ item }">
          <v-btn 
            v-if="item.status==='pending' && ! item.approval_date"
            icon
            color="orange"
            @click="approve(item.study_id)"
          >
            <v-icon>mdi-stamper</v-icon>
          </v-btn>
          <v-btn 
            v-else-if="item.status==='pending' && ! item.raw_upload_date"
            icon
            color="blue"
            @click="raw_upload(item)"
          >
            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
          <span v-else-if="item.status==='pending' && ! item.tech_report_date">
            <v-btn 
              icon
              color="pink"
              @click="raw_download(item.study_id)"
            >
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
            <v-btn 
              icon
              color="teal"
              :to="{name: 'report', params: {study_id: item.study_id} }"
            >
              <v-icon>mdi-clipboard-pulse</v-icon>
            </v-btn>
          </span>
          <span v-else-if="item.status==='pending' && ! item.doc_report_date">
            <v-btn 
              icon
              color="purple"
              :to="{name: 'report', params: {study_id: item.study_id} }"
            >
              <v-icon>mdi-clipboard-pulse</v-icon>
            </v-btn>
          </span>
        </template>
        <template v-slot:item.abnormal="{ item }">
          <Superchip 
            v-if="item.abnormal"
            icon='mdi-flag'
            tip="abnormal report"
            color="red"
          />
        </template>
        <template v-slot:item.patient_dob="{ item }">
          {{age(item.patient_dob)}}
        </template>
        <template v-slot:item.duration_numeric="{ item }">
          {{item.duration}}
        </template>
        <template v-slot:item.start_date="{ item }">
          {{pretty_date(item.start_date)}}
        </template>
        <template v-slot:item.modify="{ item }">
          <Modifydropdown 
            :patient="item"
            :actions="revisitable"
            @approve="approve($event)"
            @raw_upload="raw_upload($event)"
            @tech_upload="tech_upload($event)"
          />
        </template>
        <!--template v-slot:body.append>
          <v-card-text class="d-flex body-1">
            <v-spacer></v-spacer><v-spacer></v-spacer>
            <span>Studies fetched:</span>
            <v-select 
              v-model="job_count" 
              :items="[100, 1000, 'All']"
            ></v-select>
          </v-card-text>
        </template-->
      </v-data-table>

      <Approval v-model="approving" :study_id="current_patient.study_id" /> 
      <Rawdata v-if="raw_uploading" :patient_data="current_patient" /> 
      <Techreport v-if="tech_uploading" :patient_data="current_patient" /> 
    </v-card>
  </v-container>
</template>

<script>
/*
2do:
- get doc report button to remain when saved but not "ready to submit"
- finish the revist menu & actions
- add pop-overs to the action buttons
- reformat start date (so its prettier)

- add a refresh button to manually check & get a response
- add :key to those 3 items showing red

done:
- add status column which uses a grey icon to say "pending approval", "pending raw data upload", "pending tech report", etc
- retrieve access permissions with the list
- send along which action is pending (& therefor which can be modified)
- only put 1 icon in the action section
- change DOB to age (with DOB as popover) & change start to days since request
- see if you can search on hidden columns (ie find them by typing their Dr's name)
- check for new jobs every minute
- add test duration to column list (after adding it to DB & registration)
- add status column

future:
- toggle a refresh icon while the empty joblist populates
- create dropdown to modify which columns are shown
- consider sectioning into 3 sections: upcoming, pending, completed
  - could create a multi-checkbox drop-down to change it
  - could instead put an edit button/icon next to the title which will then dropdown to give all the options
- clicking on name will bring up their demographics (name, DOB, OHIP, phone, etc)
- just because a doc is allowed to approve or upload a holter doesnt mean he wants to see that button...
  ... create a way to hide those buttons for docs but still make them available if they want them
- figure out how to get custom sorting working with multiple/grouped columns
  - see if there's any good documentation out there
    - here?: https://stackoverflow.com/questions/52678905/how-to-use-vutifys-custom-sort
    - check if anyone responded to my last 2 vue.js comment where I asked for doc help
    - look into sortBy="date" & update: sort-desc props (also here: https://stackoverflow.com/questions/52678905/how-to-use-vutifys-custom-sort)
  - disect how it works with a shit-ton of console logs
- add Status Duration column (Days since status changed)
- write the code to get the user read & write roles both in API & browser
*/
import { mapState } from 'vuex';
import Modifydropdown from "@/joblist/modifydropdown.vue"
import Approval from "@/joblist/approval/approval.vue"
import Rawdata from "@/joblist/raw_data/Rawdata.vue"
import Techreport from "@/joblist/tech_report/Techreport.vue"
import Superchip from "@/joblist/doc_report/superchip.vue"
export default {
  components: {Modifydropdown, Approval, Rawdata, Techreport, Superchip},
  computed: {
    ...mapState({
      jobs: state => state.joblist.jobs,
      user: state => state.login.user,
      raw_data_dialog: state => state.joblist.raw_data_dialog
    }),
    revisitable() {
      if (!this.user) {return []}
      let output = []
      if ([...this.user.read, ...this.user.write].includes(0) || [...this.user.read, ...this.user.write].includes(1)) {
        output.push('approve')
      }
      if (this.user.write.includes(2)) { output.push('upload_raw_data') }
      if (this.user.read.includes(2)) { output.push('download_raw_data') }
      //if (this.user.write.includes(3)) { output.push('upload_tech_report') }
      if (this.user.read.includes(3)) { output.push('download_tech_report') }
      if ([...this.user.write, ...this.user.read].includes(4)) { output.push('cardio_report') }
      if (this.user.read.includes(5)) { output.push('final_report') }
      return output
    }
  },
  data () {
    return {
      search: '',
      headers: [
          { text: '', value: 'action', sortable: false },
          { text: '', value: 'abnormal' },
          { text: 'Study', value: 'study_id', align: 'start',  },
          { text: 'Name', value: 'patient_name' },
          { text: 'Age', value: 'patient_dob' },
          { text: 'OHIP', value: 'patient_ohip', align: ' d-none' },
          { text: 'Status', value: 'status', align: ' d-none' },
          { text: 'Referring Physician', value: 'referring_dr', align: ' d-none' },
          { text: 'Primary Physician', value: 'primary_dr', align: ' d-none' },
          { text: 'Duration', value: 'duration_numeric' },
          { text: 'Start Date', value: 'start_date' },
          { text: 'Revisit', value: 'modify', sortable: false },
        ],
      studies_fetched: 100,
      //job_count: 100,

      approving: false,
      raw_uploading: false,
      tech_uploading: false,
      current_patient: {}
    }
  },
  created () {
    console.log('hot-reloaded @ ', new Intl.DateTimeFormat("en" , {timeStyle: "short"}).format(Date.now()))
    this.$store.dispatch('activateJoblistRefresher')
  },
  watch: {
    user () {
      console.log('user changed')
      if (this.user === null) {
        this.$store.dispatch('deactivateJoblistRefresher')
      } else {
        this.$store.dispatch('activateJoblistRefresher')
      }
    },
    approval_dialog () {
      if (! this.approval_dialog) {
        this.approving = false
        this.current_patient = {}
      }
    },
    raw_data_dialog () {
      if (! this.raw_data_dialog) {
        this.raw_uploading = false
        this.current_patient = {}
      }
    },
    tech_report_dialog () {
      if (! this.tech_report_dialog) {
        this.tech_uploading = false
        this.current_patient = {}
      }
    },
    studies_fetched(studies){
      this.$store.dispatch('jobFetchLimit', studies)
    }
  },
  methods: {
    age(dob) {
      const miliseconds_per_yr = 31536000000
      const miliseconds_since_birth = new Date().getTime() - new Date(dob).getTime()
      return parseInt(miliseconds_since_birth/miliseconds_per_yr)
    },
    pretty_date(date) {
      return new Date(date).toLocaleDateString("en-US", {day: 'numeric', month: 'short', year:'numeric'})
    },
    //customSort(items, index, isDesc) {
    //  if (index[0] === "duration") {
    //    items.sort((a, b) => {
    //      if (!isDesc[0]) {
    //        return parseInt(a[index].split(' ')[0]) < parseInt(b[index].split(' ')[0]) ? -1 : 1;
    //      } else {
    //        return parseInt(b[index].split(' ')[0]) < parseInt(a[index].split(' ')[0]) ? -1 : 1;
    //      }
    //    })
    //  } else if (index[0] === "status") {
    //    items.sort((a, b) => {
    //      if (!isDesc[0]) {
    //        return a[index[index.length-1]][3] < b[index[index.length-1]][3] ? -1 : 1;
    //      } else {
    //        return b[index[index.length-1]][3] < a[index[index.length-1]][3] ? -1 : 1;
    //      }
    //    })
    //  } else {
    //    items.sort((a, b) => {
    //      if (!isDesc[0]) {
    //        return a[index] < b[index] ? -1 : 1;
    //      } else {
    //        return b[index] < a[index] ? -1 : 1;
    //      }
    //    })
    //  }
    //  return items
    //},
    approve(study_id) {
      this.current_patient = {study_id: study_id}
      this.approving = true
    },
    raw_upload(patient_data) {
      this.current_patient = patient_data
      this.raw_uploading = true
      this.$store.dispatch('set_var', {variable: 'raw_data_dialog', value: true})
    },
    raw_download(study_id) {
      window.open(process.env.VUE_APP_API_URL + "/api/raw_data_fetch/" + study_id)
    },
    tech_upload(patient_data) {
      this.current_patient = patient_data
      this.tech_uploading = true
      this.$store.dispatch('set_var', {variable: 'tech_report_dialog', value: true})
    },
    tech_download(study_id) {
      window.open(process.env.VUE_APP_API_URL + "/api/tech_report_fetch/" + study_id)
    },
    doc_report(study_id) {
      console.log('doc_report', study_id)
    }
  }
}
</script>