<template>
  <div class="d-flex">
    <v-text-field 
      v-model="short" 
      outlined 
      dense 
      @change="change()"
      label="short-hand"
      hint="the contents of this box will appear in the drop-down menu"
    ></v-text-field>
    <v-textarea 
      v-model="full" 
      rows="3" 
      outlined 
      dense 
      @change="change()"
      label="report text"
      hint="the contents of this box will appear on the report (when its corresponding short-hand is selected from the menu)"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    short_hand: String,
    full_text: String,
  },
  data: () => ({
    short: '',
    full: '',
  }),
  watch: {
    short_hand(short_hand) { this.short = short_hand},
    full_text(full_text) { this.full = full_text},
  },
  methods: {
    change() {
      this.short.trim()
      this.full.trim()
      this.$emit('change',{short_hand: this.short, full_text: this.full})
    }
  },
  created() {
    this.short = this.short_hand
    this.full = this.full_text
  }
}
</script>