<template>
  <v-card
    class="d-flex flex-nowrap align-center"
    flat
    tile
  >
    <v-card
      tile
      flat
    >
      <v-checkbox
        v-model="checked_val"
        hide-details
        class="pt-0 mt-0"
        @change="change"
      ></v-checkbox>
    </v-card>

    <v-card
      flat
      tile
      width="75"
      height="50"
    >
      <v-combobox
        v-model="delivery_method_val"
        :items="delivery_methods"
        allow-overflow
        solo
        flat
        class="align-center"
        :readonly="readonly"
        :disabled="disabled"
        @change="change"
      >
        <template v-slot:selection="data">
          <v-icon v-if="disabled && data.item==='mdi-fax'">mdi-close</v-icon>
          <v-icon v-else-if="disabled">{{ data.item }}-off</v-icon>
          <v-icon v-else>{{ data.item }}</v-icon>
        </template>
        <template v-slot:item="{ item }" @click="yo">
          <v-icon>{{ item }}</v-icon>
          {{ item.split('-')[1] }}
        </template>
      </v-combobox>
    </v-card>

    <v-card
      flat
      tile
      class="d-flex flex-wrap align-content-center flex-grow-1"
    >
      <v-card
        tile
        flat
        class="flex-grow-1 flex-shrink-1 mr-2"
      >
        <v-text-field
          v-model="address_val"
          :label="method_name.replace(/\b[a-z]/g, (x) => x.toUpperCase())"
          :hint="method_name+' where the final report will be sent'"
          :readonly="readonly"
          :disabled="disabled"
          :class="{ 'disabled': disabled }"
          @change="change"
        >
        </v-text-field>
      </v-card>
      
      <v-card
        tile
        flat
        class="flex-grow-1 flex-shrink-1 mr-2"
      >
        <v-text-field
          v-model="title_val"
          label="Recipient"
          :hint="'name associated with this ' + method_name"
          :readonly="readonly"
          :disabled="disabled"
          :class="{ 'disabled': disabled }"
          @change="change"
        ></v-text-field>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
/*
2do:
- have email/fax number validation rules applied depending on which method is selected
- refactor destination.vue to use vuex instead of shitty props:
  - vuex needs to only keep track of 1 "destinations" array or object
  - when a destination is created it should add its contents to the array & store its index locally
*/
export default {
  props: {
    checked: Boolean,
    delivery_method: String,
    address: String,
    title: String,
    readonly: Boolean,
    ignore_untouched: Boolean
  },
  watch: {
    delivery_method: {
      handler(delivery_method) {
        if (!(this.delivery_methods.includes(delivery_method))) {
          this.delivery_method_val = 'mdi-email'
          //console.log('default', delivery_method, this.delivery_methods, this.delivery_methods.includes(delivery_method))
        } else {
          //console.log('update')
          this.delivery_method_val = delivery_method
        }
        this.change()
      }
    },
    address: {
      handler(address) {
        this.address_val = address
        this.change()
      }
    },
    title: {
      handler(title) {
        this.title_val = title
        this.change()
      }
    }
  },
  computed: {
    method_name() {
      if (this.delivery_method_val === 'mdi-fax') {
        return "fax number"
      }
      if (this.delivery_method_val === 'mdi-email') {
        return "email address"
      }
      return ''
    }
  },
  data: () => ({
    checked_val: false,
    delivery_method_val: 'mdi-fax',
    address_val: '',
    title_val: '',

    delivery_methods: ['mdi-fax', 'mdi-email'],
    disabled: true,
    untouched: true,

    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ]
  }),
  methods: {
    change() {
      if (!this.readonly && this.untouched) {
        this.checked_val = true
      }
      this.disabled = !this.checked_val
      this.$emit('change',{checked: this.checked_val,
                           delivery_method: this.delivery_method_val,
                           address: this.address_val,
                           title: this.title_val,
                           untouched: this.untouched})
      this.untouched = false
    }
  },
  created() {
    //console.log('created', this.id)
    this.checked_val = this.checked
    if (this.delivery_method){ 
      this.delivery_method_val = this.delivery_method
    }
    if (this.title){ 
      this.title_val = this.title
    }
    if (this.address){ 
      this.address_val = this.address
    }
    if (this.readonly) {
      this.disabled = !this.checked
    } else {
      this.disabled = false
    }
    if (this.ignore_untouched) {
      this.untouched = false
    }
  }
}
</script>

<style scoped>
  .disabled >>> .v-text-field__slot input {
    text-decoration: line-through
  }
</style>