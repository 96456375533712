import Vue from 'vue'
import Vuex from 'vuex'

import nav from '@/nav/nav.state.js'
import request from '@/request/request.state.js'
import login from '@/login/login.state.js'
import joblist from '@/joblist/joblist.state.js'
import approval from '@/joblist/approval/approval.state.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    nav,
    request,
    login,
    joblist,
    approval
  }
})
 