<template>
  <v-app-bar
    :app="!doc_report"
    v-if="!embedded"
  >
    <v-app-bar-nav-icon @click="navleft_open = true"></v-app-bar-nav-icon>
    <router-link :to="{name: 'Home' }">
    <v-img
      class="mt-2"
      max-height="40"
      max-width="225"
      contain
      :src="require('@/assets/textlogo.png')"
    ></v-img>
    </router-link>

    <v-spacer></v-spacer>

    <div v-if="user">
      <v-list-item href="/joblist" >
        <v-list-item-content>
          <v-list-item-title class="title">
            Worklist
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <div v-else>
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            link
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title class="title">
                For Physicians
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item :to="{name: 'About'}" link >
            <v-list-item-title>
              FAQ For Physicians
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'Request'}" link >
            <v-list-item-title>
              Online Holter Referral
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="myKardio referral.pdf" link target="_blank" >
            <v-list-item-title>
                Paper Holter Referral
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="user">
          <v-list-item-title>
            <v-btn 
              :to="{name: 'Job List'}"
              link
              exact
              text
            >
              Job List
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user">
          <v-list-item-title>
            <v-btn 
              @click="profile_action('logout')"
              text
            >
              logout
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-title>
            <v-btn 
              @click="profile_action('login')"
              text
            >
              login
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="login_dialog"
      width="500"
    >
      <login />
    </v-dialog>

  </v-app-bar>
</template>
<script>
/*
2do:
- implement login dropdown as a v-list instead so you can use vuetify's nice designs
*/
import { mapState } from 'vuex';
import login from "@/login/Login.vue"
export default {
  components: {
    login
  },
  data() {
    return {
      profile_links: ['login', 'logout']
    }
  },
  computed: {
    ...mapState({
      user: state => state.login.user
    }),
    embedded() {
      return this.$route.name === 'Embedded'
    },
    doc_report() {
      return this.$route.name === 'report'
    },
    navleft_open: {
      get() {
        return this.$store.state.nav.navleft_open
      },
      set(newval) {
        this.$store.dispatch('navleft', newval)
      }
    },
    login_dialog: {
      get() {
        return this.$store.state.login.login_dialog
      },
      set(newval) {
        this.$store.dispatch('login_dialog', newval)
      }
    }
  },
  methods: {
    profile_action(action) {
      if (action === 'login') {
        this.login_dialog = true
      } else if (action === 'logout') {
        this.logout()
      }
    },
    logout() {
      this.$store.dispatch('logout')
    }
  },
}
</script>