var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Job List "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.jobs,"search":_vm.search,"multi-sort":false,"footer-props":{
        prevIcon: '',
        nextIcon: '',
        disablePagination: true,
        itemsPerPageText: 'Studies fetched',
        itemsPerPageOptions: [100, 1000, -1],
        pagination: {}
      },"items-per-page":_vm.studies_fetched},on:{"update:itemsPerPage":function($event){_vm.studies_fetched=$event},"update:items-per-page":function($event){_vm.studies_fetched=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [(item.status==='pending' && ! item.approval_date)?_c('v-btn',{attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.approve(item.study_id)}}},[_c('v-icon',[_vm._v("mdi-stamper")])],1):(item.status==='pending' && ! item.raw_upload_date)?_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.raw_upload(item)}}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1):(item.status==='pending' && ! item.tech_report_date)?_c('span',[_c('v-btn',{attrs:{"icon":"","color":"pink"},on:{"click":function($event){return _vm.raw_download(item.study_id)}}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1),_c('v-btn',{attrs:{"icon":"","color":"teal","to":{name: 'report', params: {study_id: item.study_id} }}},[_c('v-icon',[_vm._v("mdi-clipboard-pulse")])],1)],1):(item.status==='pending' && ! item.doc_report_date)?_c('span',[_c('v-btn',{attrs:{"icon":"","color":"purple","to":{name: 'report', params: {study_id: item.study_id} }}},[_c('v-icon',[_vm._v("mdi-clipboard-pulse")])],1)],1):_vm._e()]}},{key:"item.abnormal",fn:function(ref){
      var item = ref.item;
return [(item.abnormal)?_c('Superchip',{attrs:{"icon":"mdi-flag","tip":"abnormal report","color":"red"}}):_vm._e()]}},{key:"item.patient_dob",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.age(item.patient_dob))+" ")]}},{key:"item.duration_numeric",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" ")]}},{key:"item.start_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pretty_date(item.start_date))+" ")]}},{key:"item.modify",fn:function(ref){
      var item = ref.item;
return [_c('Modifydropdown',{attrs:{"patient":item,"actions":_vm.revisitable},on:{"approve":function($event){return _vm.approve($event)},"raw_upload":function($event){return _vm.raw_upload($event)},"tech_upload":function($event){return _vm.tech_upload($event)}}})]}}])}),_c('Approval',{attrs:{"study_id":_vm.current_patient.study_id},model:{value:(_vm.approving),callback:function ($$v) {_vm.approving=$$v},expression:"approving"}}),(_vm.raw_uploading)?_c('Rawdata',{attrs:{"patient_data":_vm.current_patient}}):_vm._e(),(_vm.tech_uploading)?_c('Techreport',{attrs:{"patient_data":_vm.current_patient}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }