<template>
  <!--v-dialog
    v-model="request_form"
    max-width="800"
  -->
  <v-row justify="center"><v-col cols="12" align="center" >
    <v-overlay absolute z-index=0 :style="{'background-image':'url(' + require('@/assets/waitingroom.jpg') + ')', 'background-attachment': 'fixed', 'background-size': 'auto 125%'}" ></v-overlay>
    <v-form
      ref="form"
      v-model="request_form"
      lazy-validation
      @submit.prevent="request_holter(false)"
    >
      <v-card max-width="800">
        <v-card-title class="headline grey lighten-2">
          Request Holter
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <!--v-subheader>Patient Details</v-subheader-->
          <v-text-field
            v-model="patient_name"
            :rules="[v => !!v || 'Name is missing']"
            validate-on-blur
            label="Patient Name"
            prepend-icon="mdi-account"
            required
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('patient_name')"
          ></v-text-field>

          <div class="d-flex">
            <v-menu
              ref="dob_activator"
              v-model="dob_activator"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }"><!--:rules="dobRules"-->
                <v-text-field
                  v-model="patient_dob"
                  validate-on-blur
                  label="Patient Date of Birth"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  clear-icon="mdi-close-circle"
                  placeholder="YYYY-MM-DD"
                  hint='enter patient date of birth in form "YYYY-MM-DD"'
                  outlined
                  filled
                  :readonly = "study_id > 0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="!study_id > 0"
                ref="dob_picker"
                v-model="patient_dob"
                :max="new Date().toISOString().substr(0, 10)"
                :min="new Date().getFullYear()-125 + '-01-01'"
                @change="save_dob"
              ></v-date-picker>
            </v-menu>

            <v-combobox
              v-model="patient_sex"
              :items="['male', 'female']"
              auto-select-first
              label="Sex"
              hint="choose the sex of the patient"
              :rules="[v => !!v || 'Sex of patient is missing']"
              outlined
              filled
              :readonly = "study_id > 0"
              @blur="autocomplete_sex"
            ></v-combobox>

          </div>

          <v-text-field
            v-model="patient_ohip"
            :rules="ohipRules"
            validate-on-blur
            label="Patient OHIP Number and Version Code"
            prepend-icon="mdi-card-account-details"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-text-field>

          <v-text-field
            v-model="patient_phone"
            :rules="[v => !!v || 'Phone Number is missing']"
            label="Patient Phone Number"
            prepend-icon="mdi-phone"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-text-field>

          <v-text-field
            v-model="patient_email"
            :rules="emailRules"
            validate-on-blur
            label="Patient E-mail"
            prepend-icon="mdi-email"
            required
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('patient_email')"
          ></v-text-field>

          <v-divider></v-divider>

          <v-textarea
            v-model="reason"
            label="Reason / Indication"
            hint="please enter the reason(s) for requesting a heart monitor"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-textarea>

          <v-textarea
            v-model="patient_medications"
            label="Cardiac Medications"
            hint="please list all relevant cardiac medications..."
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-textarea>

          <v-combobox
            v-model="duration"
            :items="durations"
            label="Test Duration"
            prepend-icon="mdi-heart-pulse"
            hint="choose how long the hear monitor will be worn for..."
            :menu-props="{ nudgeBottom: 25 }"
            :rules="[v => !!v || 'Test duration is missing']"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-combobox>

          <v-divider class="my-2"></v-divider>
          <v-subheader>Physician Information</v-subheader>

          <v-text-field
            v-model="primary_dr"
            label="Primary Physician"
            :rules="[v => !!v || 'Primary care provider is missing']"
            prepend-icon="mdi-hospital"
            hint="please enter the name of the patient's primary care provider..."
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('primary_dr')"
          ></v-text-field>

          <v-text-field
            v-model="referring_dr"
            label="Referring Physician"
            prepend-icon="mdi-stethoscope"
            hint="if not the primary physician, please enter the name of the care provider referring this patient..."
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('referring_dr')"
          ></v-text-field>

          <v-text-field
            v-model="dr_billing"
            :rules="[v => !!v || 'Physician OHIP billing number is missing']"
            label="Doctor Billing Number"
            hint="enter OHIP billing number of referring physician"
            prepend-icon="mdi-credit-card"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-text-field>

          <v-text-field
            v-model="dr_phone"
            label="Physician Phone Number"
            :rules="[v => !!v || 'Phone number is missing']"
            prepend-icon="mdi-phone-classic"
            hint="enter phone number of referring physician"
            outlined
            filled
            :readonly = "study_id > 0"
          ></v-text-field>

          <v-text-field
            v-model="dr_fax"
            label="Physician Fax Number"
            :rules="[v => !!v || 'Fax number is missing']"
            prepend-icon="mdi-fax"
            hint="enter fax number of referring physician"
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('dr_fax')"
          ></v-text-field>

          <v-text-field
            v-model="dr_email"
            label="Physician Email"
            prepend-icon="mdi-email"
            hint="enter email address of referring physician"
            outlined
            filled
            :readonly = "study_id > 0"
            @change = "chrome_update_hack('dr_email')"
          ></v-text-field>

          <v-divider></v-divider>
          <v-subheader>Holter Delivery</v-subheader>

          <shipping :recipients="recipients" :readonly = "study_id > 0" />

          <v-divider></v-divider>
          <v-subheader>Final Report Destinations</v-subheader>

          <destination
            v-if="patient_email"
            readonly
            delivery_method = "mdi-email"
            :address = "patient_email"
            :title = "patient_name + '\'s email'"
            @change = "fixed_destinations[0] = $event"
          />

          <destination
            v-if="dr_fax"
            readonly
            checked
            delivery_method = "mdi-fax"
            :address = "dr_fax"
            :title = "(this.referring_dr || this.primary_dr) + '\'s fax'"
            @change = "fixed_destinations[1] = $event"
          />

          <destination
            v-if="dr_email"
            id="dr_email"
            readonly
            checked
            delivery_method = "mdi-email"
            :address = "dr_email"
            :title = "(this.referring_dr || this.primary_dr) + '\'s email'"
            @change = "fixed_destinations[2] = $event"
          />
        
          <span v-for="(dest, index) in destinations" :key="dest.id">
          <destination
            :readonly = "study_id > 0"
            @change = "update_destination(index, $event)"
          />
          </span>

        <v-divider></v-divider>
        <v-subheader>Additional Information:</v-subheader>

        <v-textarea
          v-model="notes"
          label="Message (optional)"
          hint="provide any additional messages, comments, or notes for the office"
          outlined
          filled
          :readonly = "study_id > 0"
        ></v-textarea>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <span v-if="study_id > 0">
            <v-icon
              @click="success_icon_id = (success_icon_id + 1) % success_icons.length"
              color="green"
            >
              {{success_icons[success_icon_id]}}
            </v-icon>
            Form submitted with study ID {{study_id}}
          </span>
          <!--v-btn
            :disabled="!valid"
            color="success darken-1"
            class="mr-4"
            @click="validate"
          >
            Validate
          </v-btn>

          <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            Reset Form
          </v-btn>

          <v-btn
            color="warning"
            @click="resetValidation"
          >
            Reset Validation
          </v-btn-->
          <v-spacer></v-spacer>
          <v-alert 
            v-if="study_id > 0"
            type="success"
            dense
            class="mb-0"
          >
            Success
          </v-alert>
          <v-btn
            v-else
            color="success"
            type="submit"
            name="button"
            :disabled="submitting"
            :loading="submitting"
          >
            Submit Request
          </v-btn>

          <simpledialog
            dialog_name="validation_dialog"
            title="Request Form Incomplete"
            body="There are a few pieces of information missing or invalid in the request form.  This could lead to potential delays in shipping your heart monitor and beginning the study.  You have the option to go back and try to correct any mistakes or submit the form as-is."
            cancel="Submit As-Is"
            ok="Go Back & Make Corrections"
            @response="validation_response"
          />
          <simpledialog
            dialog_name="success_dialog"
            title="Success!"
            :body="'Your request has been received and given an ID of ' + study_id + '.  You can expect to be contacted in the coming days with an update and if approved your heart monitor will shipped to you promptly.  We look forward to help you achieve a healthier you!'"
            cancel=""
            ok="okay"
            @response="success_response"
          />
          <simpledialog
            dialog_name="retry_dialog"
            title="Something went wrong"
            :body="'We apologize for the inconvenience but your request failed with the following message: \'\'' + retry_error + '.\'\'  Please try again.  If you continue to receive this message please contact our office so we can remedy the issue.'"
            cancel="cancel"
            ok="try again"
            @response="retry_response"
          />

        </v-card-actions>
      </v-card>
    </v-form>
  </v-col></v-row>
</template>

<script>
/*
2do:
- don't report validation issues when pickup selected & address not entered
- fix destination showing up as unchecked when its acutally checked
- fix the date showing invalid when selected with datepicker
- make the dialogs prettier (put a huge checkmark in the success dialog)
  - add another prop that'll take a big icon
  - change the background color of the body or title & action bars
  - add dividers (if not there yet)
  - consider passing body text as an array of paragraphs
- add a nice background picture
- add "hint"s to some of the text areas
- fix weird bug that occurs when an alternate address is entered... the v-for loop creates 2 new <destination> instead of just 1 new 1:
  - this has the down-side of making the cursor loose focus
- ensure clicking any part of the DOB field brings up the datepicker
- ensure server not reachable gives better feedback
- should especially validate the fax & email report destination fields (or at least bring it up to the approvers)

- allow dr email to be validated only if there's text in there
- recipient field might be acting weird, possibly depending on the order the form is filled... check it out
- after submission make fields look more like they're disabled but still allow for highlighting
- show clearable icon when hovered over or field is active
  - provided that pressing tab to navigate between fields doesn't focus it
- create a summary of warnings in validation dialog
- ensure axios call conforms to vuex best practices (refactor into a service.js?)
- format things like dates, OHIP number, and phone numbers while typing:
  - use packages like vue-phone-number-input or v-currency-field etc
    - or even better, they may have
- set autocomplete="email" or autocomplete="phone", etc for physicians (or for patient if patient filling it out)
- change cursor to pointer on fax/email combobox (https://stackoverflow.com/questions/61785869/how-to-set-cursor-to-pointer-on-vuetify-v-data-table-rows)
- prevent datepicker from crashing from typos
- DoB should be saved with the local timezone (Nov 2 in Hawaii != Nov 2 in Japan)

done:
- collect sex, holter duration
 - add variable-length report destination(s) section
  done: - choose layout
  done:- implement layout
    done: - select box
    done: - fax/email:
      done: - style
      done: - alignment
      done: - width
    done:- address (width & spacing)
    done: - title (width & spacing)
  done: - modulize/componentize
  done: - implement underlying data sctructure & bindings
done(ish)- learn flex/grid system so that I can implement the following...
done: - float the form in a card or modal
done: - allow DOB to be chosen by typing (can use the 1 I used for old WHI holter app)
  done: - show yyyy-mm-dd when clicked
  done: - move the picker down so that the field is fully visible
  done: - keep the cursor in the menu/textarea
done: - refactor state.js actions to pass the variable name and new value
  done: - store data in an object instead of individual variables
done: - add holter delivery section
done: - ensure data makes it to it's destination as expected
done: - handle dialog upon submission
done: - change rules to warnings that pop up in a dialog upon attempted submission
done: - write axios call to 'https://api.ipify.org?format=json' and attach IP to form submission
done: - use clearer dividers or shaded areas to seperate logical areas
done: - tackle window resizing and how that'll effect all elements
*/
import destination from "@/request/destination.vue"
import shipping from "@/request/shipping.vue"
import simpledialog from "@/request/simpledialog.vue"

let form_variables = {
  patient_name: '',
  patient_dob: null,
  patient_sex: '',
  patient_ohip: '',
  patient_phone: '',
  patient_email: '',

  reason: '',
  patient_medications: '',
  duration: '',

  primary_dr: '',
  referring_dr: '',
  dr_billing: '',
  dr_phone: '',
  dr_fax: '',
  dr_email: '',

  notes: ''
}
let shipping_address = [
  'delivery',
  'recipient',
  'country',
  'street_address1',
  'street_address2',
  'city',
  'province',
  'zip'
]

function HCV(ohip) {
  ohip = ohip.replaceAll(' ', '').replaceAll('-', '')
  if (!ohip) {
    return {error: 'OHIP number is missing'}
  }
  if (! [10, 12].includes(ohip.length)) {
    return {error: 'invalid number of characters in OHIP number.  Please check the card and try again'}
  }
  if (!RegExp('\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d').test(ohip.substr(0,10))) {
    return {error: 'invalid OHIP number: first 10 characters should be digits.  Please check the card and try again'}
  }
  if (ohip.substr(10,12) && !RegExp('[a-z,A-Z][a-z,A-Z]').test(ohip.substr(10,12))) {
    return {error: 'invalid OHIP version code: last 2 characters should be letters.  Please check the card and try again'}
  }

  var bottom_sum = 0
  var position = 0
  for (const digit of ohip.substr(0,9)) {
    if (position % 2 === 0 ) {
      const partial_sum = (2 * parseInt(digit)).toString()
      for (const digit2 of partial_sum) {
        bottom_sum = bottom_sum + parseInt(digit2)
      }
    } else {
      bottom_sum = bottom_sum + parseInt(digit)
    }
    position = position + 1
  }
  const check_bit = 10 - parseInt(bottom_sum.toString().slice(-1))
  if (check_bit != parseInt(ohip[9])) {
    return {error: 'invalid OHIP number: at least 1 of the digits is incorrect.  Please check the card and try again'}
  }

  return {valid: true}
}
function DOB(dob) {
  if (! dob){
    return {error: 'Date of Birth is missing'}
  }
  const ymd = dob.split('-')
  if (ymd.length != 3) {
    return {error: 'Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (ymd[0].length != 4) {
    return {error: 'Invalid date format: please give full 4-digit year'}
  }
  const max_yr = parseInt(new Date().toISOString().substr(0, 10))
  const min_yr = parseInt(new Date().getFullYear()-125 + '-01-01')
  if (parseInt(ymd[0]) > max_yr || parseInt(ymd[0]) < min_yr ) {
    return {error: 'Year is out of bounds.  Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (parseInt(ymd[1]) > 12 || parseInt(ymd[1]) < 1 ) {
    return {error: 'Month is out of bounds.  Expecting date format of YYYY-MM-DD where YYYY is the year, MM is the month, and DD is the day (ie. 1982-11-02 for November 2nd 1982)'}
  }
  if (parseInt(ymd[2]) > 31 || parseInt(ymd[2]) < 1 ) {
    return {error: 'Day is out of bounds.  Please check the day and try again'}
  }
  return {valid: true}
}

export default {
  components: { destination, shipping, simpledialog },
  data: () => ({
    ...form_variables,
    request_form: true,
    fixed_destinations: [{}, {}, {}],
    destinations: [{}],

    durations: ['1 day', '2 days', '3 days', '7 days', '14 days'],
    dob_activator: false,
    dobRules: [ v => !! DOB(v).valid || DOB(v).error ],
    ohipRules: [ v => !! HCV(v).valid || HCV(v).error ],
    emailRules: [
      v => !!v || 'E-mail is missing',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    retry_error: '',
    submitting: false,
    study_id: 0,

    success_icon_id: 0,
    success_icons: [
        'mdi-emoticon',
        'mdi-emoticon-wink',
        'mdi-emoticon-cool',
        'mdi-emoticon-dead',
        'mdi-emoticon-excited',
        'mdi-emoticon-happy',
        'mdi-emoticon-neutral',
        'mdi-emoticon-tongue'
      ],
  }),
  computed: {
    recipients() { 
      return [...new Set([
        (this.referring_dr || this.primary_dr) ?
          this.patient_name + ' c/o ' + (this.referring_dr || this.primary_dr) :
          this.patient_name,
        this.referring_dr,
        this.primary_dr])].filter(item => item)
    },
    validation_dialog: {
      get() {
        return this.$store.state.request.validation_dialog
      },
      set(newval) {
        this.$store.dispatch('set_var', {variable: 'validation_dialog', value: newval})
      }
    },
    success_dialog: {
      get() {
        return this.$store.state.request.success_dialog
      },
      set(newval) {
        this.$store.dispatch('set_var', {variable: 'success_dialog', value: newval})
      }
    },
    retry_dialog: {
      get() {
        return this.$store.state.request.retry_dialog
      },
      set(newval) {
        this.$store.dispatch('set_var', {variable: 'retry_dialog', value: newval})
      }
    }
  },
  watch: {
    dob_activator (val) {
      if (this.study_id === 0) {
        val && setTimeout(() => (this.$refs.dob_picker.activePicker = 'YEAR'))
      }
    },
  },
  methods: {
    chrome_update_hack(var_name){
      this[var_name] = this[var_name] + ' '
      this[var_name].trim()
    },
    autocomplete_sex() {
      if (['m', 'ma','mal','male'].includes(this.patient_sex.toLowerCase())) {
        this.patient_sex = 'male'
      } else if (['f', 'fe', 'fem', 'fema', 'femal', 'female'].includes(this.patient_sex.toLowerCase())) {
        this.patient_sex = 'female'
      }
    },
    update_destination (id, data) {
      if (data.untouched) {
        this.destinations.push({})
      }
      this.destinations[id] = data
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    save_dob (dob) {
      this.$refs.dob_activator.save(dob)
    },
    request_holter(as_is) {
      let completed_form = {shipping_address: {}}
      for (const entry in form_variables) {
        completed_form[entry] = this[entry]
      }
      for (const entry of shipping_address) {
        completed_form.shipping_address[entry] = this.$store.state.request[entry]
      }
      completed_form.report_destinations = [...this.fixed_destinations, ...this.destinations]

      if (! this.$refs.form.validate() && ! as_is ){
        this.validation_dialog = true
        return null
      }
      this.submitting = true
      this.$store.dispatch('request_holter', completed_form)
      .then( (response) => {
        this.submitting = false
        //console.log('response from dispatch:',response)
        if (response.study_id) {
          this.study_id = response.study_id
          this.success_dialog = true
          if (response.error) {console.log(response)}
          return null
        }
        if (response.error) {
          this.retry_error = response.error
          this.retry_dialog = true
          console.log(response)
        }
      })
    },
    validation_response(yes) {
      if (!yes) {this.submitting = true}
      this.validation_dialog = false
      if (!yes) {this.request_holter(true)}
    },
    success_response() {
      this.success_dialog = false
    },
    retry_response(yes) {
      if (yes) {this.submitting = true}
      this.retry_dialog = false
      if (yes) {this.request_holter(true)}
    },
  },
  created() {
    console.log('hot-reloaded @ ', new Intl.DateTimeFormat("en" , {timeStyle: "short"}).format(Date.now()))
  }
}
</script>
